import { removeFromBoxFlashCard } from '../../services/cloudServices'

const removeFromBoxUseCase = async ({ ids }) => {
  const result = await removeFromBoxFlashCard(
    {
      ids: ids
    }
  )

  return result
}

export default removeFromBoxUseCase
