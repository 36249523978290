import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface NavigationState {
  currentPath: string
  prevPath: string
}

const initialState: NavigationState = {
  currentPath: '/',
  prevPath: '/'
}

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    // Set the current path, automatically updating previous path
    setPath: (state, action: PayloadAction<string>) => {
      state.prevPath = state.currentPath
      state.currentPath = action.payload
    }
  }
})

// Export the actions generated by createSlice
export const { setPath } = navigationSlice.actions

// Export the slice reducer to add to the store
export default navigationSlice.reducer
