import flashCardsMapper from '../../services/mapper/memoryBox/flashCardsMapper'
import { ASC, HISTORY } from '../../enums/globalEnums/globalEnums'
import { fetchAllHistoryMemoryBoxFlashCards } from '../../services/cloudServices'

const getAllHistoryFlashCardUseCase = async ({ categoryId, searchTerm, sortBy, sortKey }) => {
  const result = await fetchAllHistoryMemoryBoxFlashCards(
    {
      boxType: HISTORY,
      categoryId: categoryId,
      frontText: searchTerm,
      sortAsc: sortBy === ASC,
      sortBy: sortKey
    }
  )

  return flashCardsMapper(result)
}

export default getAllHistoryFlashCardUseCase
