import fetchReviewMistakesMapper from '../../services/mapper/sections/fetchReviewMistakesMapper'
import { fetchReviewMistake } from '../../services/cloudServices'

const getReviewMistakesUseCase = async ({ unit, studyType }) => {
  const response = await fetchReviewMistake({ unit, studyType })

  return fetchReviewMistakesMapper(response)
}

export default getReviewMistakesUseCase
