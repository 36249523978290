import levelsMapper from '../../services/mapper/levelAndUnit/levelsMapper'
import { updateLevelStatus } from '../../services/cloudServices'

const updateLevelStatusUseCase = async ({ level }) => {
  const response = await updateLevelStatus({ level })

  return levelsMapper(response)
}

export default updateLevelStatusUseCase
