import exerciseStatisticsMapper from 'services/mapper/exerciseStatistics/exerciseStatisticsMapper'
import { fetchExerciseStatistics } from 'services/cloudServices'

const exerciseStatisticsUseCase = async () => {
  const response = await fetchExerciseStatistics(null)

  return exerciseStatisticsMapper(response)
}

export default exerciseStatisticsUseCase
