import getUnitMapper from '../../services/mapper/levelAndUnit/getUnitMapper'
import { fetchUnitById } from '../../services/cloudServices'

const getUnitUseCase = async ({ unitId }) => {
  const response = await fetchUnitById({ unitId })

  return getUnitMapper(response?.[0])
}

export default getUnitUseCase
