import fetchExerciseMapperSwitch from '../../services/mapper/exercise/fetchExerciseMapperSwitch'
import { toNumber } from 'lodash'
import { fetchSectionExercise } from '../../services/cloudServices'

const sectionExerciseUseCase = async ({ unit, section, sectionNumber, groupType, studyStatus }) => {
  const response = await fetchSectionExercise({ unit, section, groupType })

  return {
    ...fetchExerciseMapperSwitch(response),
    unitNumber: toNumber(unit),
    sectionNumber: toNumber(sectionNumber),
    groupType,
    studyStatus
  }
}

export default sectionExerciseUseCase
