import radioStationMapper from '../../services/mapper/radioStation/radioStationMapper'
import { fetchRadioStations } from '../../services/cloudServices'

const radioStationUseCase = async () => {
  const result = await fetchRadioStations(null)

  return radioStationMapper(result)
}

export default radioStationUseCase
