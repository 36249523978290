import unitSummeryMapper from '../../services/mapper/memoryBox/unitSummeryMapper'
import { fetchUnitSummeryInMemoryBox } from '../../services/cloudServices'

const getUnitsSummeryUseCase = async ({ categoryId }) => {
  const response = await fetchUnitSummeryInMemoryBox({ categoryId })

  return response.map(item => unitSummeryMapper(item))
}

export default getUnitsSummeryUseCase
