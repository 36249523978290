import homeStatisticsMapper from '../../services/mapper/homepage/homeStatisticsMapper'
import { fetchHomeStatistics } from '../../services/cloudServices'

const homeStatisticsUseCase = async ({ hasPlan }) => {
  let result

  if (hasPlan) {
    result = await fetchHomeStatistics(null)
  } else {
    result = []
  }

  return homeStatisticsMapper(result)
}

export default homeStatisticsUseCase
