import aiPromptMapper from '../../services/mapper/aiPrompt/aiPromptMapper'
import { postAiExercisePrompt } from '../../services/cloudServices'

const aiExerciseUseCase = async data => {
  const result = await postAiExercisePrompt(data)

  return aiPromptMapper(result, data)
}

export default aiExerciseUseCase
