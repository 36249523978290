import flashCardsMapper from '../../services/mapper/memoryBox/flashCardsMapper'
import { ASC } from '../../enums/globalEnums/globalEnums'
import { fetchAllMemoryBoxFlashCards } from '../../services/cloudServices'

const getAllFlashCardUseCase = async ({ categoryId, searchTerm, sortBy, sortKey }) => {
  const result = await fetchAllMemoryBoxFlashCards(
    {
      categoryId: categoryId,
      frontText: searchTerm,
      sortAsc: sortBy === ASC,
      sortBy: sortKey
    }
  )

  return flashCardsMapper(result)
}

export default getAllFlashCardUseCase
