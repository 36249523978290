import aiPromptFeedbackMapper from '../../services/mapper/aiPromptFeedback/aiPromptFeedbackMapper'
import { postAiPromptFeedback } from '../../services/cloudServices'

const aiPromptFeedbackUseCase = async data => {
  const result = await postAiPromptFeedback(data)

  return aiPromptFeedbackMapper(result)
}

export default aiPromptFeedbackUseCase
