import { createSlice } from '@reduxjs/toolkit'
import fetchStructureMapper from '../../services/mapper/sections/fetchStructureMapper'
import { baseAsyncThunk } from '../baseAsyncThunk'
import { ContentTypesStructureEnums } from '../../enums/structureEnums/templateType'
import { fetchMoreSectionsService, fetchSection } from '../../services/cloudServices'

const initialState = {
  loading: false,
  data: {
    id: null,
    unitId: null,
    sectionType: null,
    sectionImageNumber: null,
    createDate: null,
    disableWordColor: false,
    isSingleView: false,
    currentPageIndex: 0,
    sectionData: {
      wordColors: [],
      singlePages: [],
      templates: []
    }
  },
  error: ''
}

export const fetchStructuredSection = baseAsyncThunk(
  'structuredSection/fetchStructuredSection',
  ({ unit, sectionType }) => {
    return fetchSection(
      {
        unit: unit,
        section: sectionType
      }
    )
  }
)

export const fetchMoreSections = baseAsyncThunk('structuredSection/fetchMoreSections', ({ moreSectionId }) => {
  return fetchMoreSectionsService({ moreSectionId })
})

const structuredSectionSlice = createSlice({
  name: 'structuredSection',
  initialState,
  reducers: {
    toggleWordColor: (state, action) => {
      if (action.payload?.templateId) {
        state.data.sectionData.templates = [...state.data.sectionData.templates].map(template => {
          if (template?.id === action.payload.templateId) {
            return {
              ...template,
              structuresData: {
                ...template.structuresData,
                disableWordColor: !template.structuresData.disableWordColor
              }
            }
          }
          return template
        })
      } else {
        state.data.disableWordColor = !state.data.disableWordColor
      }
    },
    toggleView: (state, action) => {
      if (action.payload?.templateId) {
        state.data.sectionData.templates = [...state.data.sectionData.templates].map(template => {
          if (template?.id === action.payload.templateId) {
            return {
              ...template,
              structuresData: {
                ...template.structuresData,
                isSingleView: !template.structuresData.isSingleView
              }
            }
          }
          return template
        })
      } else {
        state.data.isSingleView = !state.data.isSingleView
      }
    },
    goPreviousPage: (state, action) => {
      if (action.payload?.templateId) {
        state.data.sectionData.templates = [...state.data.sectionData.templates].map(template => {
          if (template?.id === action.payload.templateId && template.structuresData.currentPageIndex > 0) {
            return {
              ...template,
              structuresData: {
                ...template.structuresData,
                currentPageIndex: template.structuresData.currentPageIndex - 1
              }
            }
          }
          return template
        })
      } else if (state.data.currentPageIndex > 0) {
        state.data.currentPageIndex = state.data.currentPageIndex - 1
      }
    },
    goNextPage: (state, action) => {
      if (state.data.currentPageIndex + 1 < state.data.sectionData?.singlePages?.length) {
        if (action.payload?.templateId) {
          state.data.sectionData.templates = [...state.data.sectionData.templates].map(template => {
            if (template?.id === action.payload.templateId) {
              return {
                ...template,
                structuresData: {
                  ...template.structuresData,
                  currentPageIndex: template.structuresData.currentPageIndex + 1
                }
              }
            }
            return template
          })
        } else {
          state.data.currentPageIndex = state.data.currentPageIndex + 1
        }
      }
    },
    goToPage: (state, action) => {
      if (typeof action.payload?.pageIndex === 'number') {
        if (action.payload?.templateId) {
          state.data.sectionData.templates = [...state.data.sectionData.templates].map(template => {
            if (template?.id === action.payload.templateId) {
              return {
                ...template,
                structuresData: {
                  ...template.structuresData,
                  currentPageIndex: action.payload.pageIndex
                }
              }
            }
            return template
          })
        } else {
          state.data.currentPageIndex = action.payload.pageIndex
        }
      }
    },
    clearStructuredSectionStates: state => {
      state.loading = false
      state.data = {
        id: null,
        unitId: null,
        sectionType: null,
        sectionImageNumber: null,
        createDate: null,
        disableWordColor: false,
        isSingleView: false,
        currentPageIndex: 0,
        sectionData: {
          wordColors: [],
          singlePages: [],
          templates: []
        }
      }
      state.error = ''
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchStructuredSection.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchStructuredSection.fulfilled, (state, action) => {
      state.data = fetchStructureMapper(action.payload.data)
      state.loading = false
    })
    builder.addCase(fetchStructuredSection.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(fetchMoreSections.fulfilled, (state, action) => {
      state.data.sectionData.templates = [...state.data.sectionData.templates].map(template => {
        if (
          template.type === ContentTypesStructureEnums.BUTTON &&
          template.config?.moreSectionId === action.payload.moreSectionId
        ) {
          return {
            ...template,
            structuresData: action.payload.data
          }
        }
        return template
      })
    })
    builder.addCase(fetchMoreSections.rejected, (state, action) => {
      state.error = action.error.message
    })
  }
})

export const { toggleWordColor, toggleView, goPreviousPage, goNextPage, goToPage, clearStructuredSectionStates } =
  structuredSectionSlice.actions

export default structuredSectionSlice.reducer
