export const utmSourceToCampaignType = (utmSource: string) => {
  switch (utmSource) {
    case 'instagram':
      return 'INSTAGRAM'
    case 'google':
      return 'GOOGLE_ADS'
    case 'telegram':
      return 'TELEGRAM'
    case 'aparat':
      return 'APARAT'
    default:
      return 'DIRECT'
  }
}
