import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchCommonDigraphSection } from '../../services/cloudServices'

const initialState = {
  loading: false,
  data: null,
  error: ''
}

export const getCommonDigraphSection = createAsyncThunk('alphabet/fetchCommonDigraphSection', () => {
  return fetchCommonDigraphSection(null)
})

const commonDigraphSlice = createSlice({
  name: 'commonDigraphSlice',
  initialState,
  // reducers: {},
  extraReducers: builder => {
    builder.addCase(getCommonDigraphSection.pending, state => {
      state.loading = true
    })
    builder.addCase(getCommonDigraphSection.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload.data
    })
    builder.addCase(getCommonDigraphSection.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

// export const {} = commonDigraphSlice.actions

export default commonDigraphSlice.reducer
