import unitsMapper from '../../services/mapper/levelAndUnit/unitsMapper'
import { updateUnitStatus } from '../../services/cloudServices'

const updateUnitStatusUseCase = async ({ level, unitId }) => {
  const response = await updateUnitStatus({ unitId })

  return unitsMapper(response, level)
}

export default updateUnitStatusUseCase
