export default [
  {
    translationKey: 'GATEWAY_CAPTCHA_VALIDATION_FAILED',
    translationValue: 'Captcha-Überprüfung fehlgeschlagen.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_CREATE_FAILED',
    translationValue: 'Benutzer konnte nicht erstellt werden.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_NOT_FOUND',
    translationValue: 'Benutzername existiert nicht.'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_INCORRECT',
    translationValue: 'Das Passwort ist falsch!'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_EMPTY',
    translationValue: 'Das Passwortfeld darf nicht leer sein.'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_LENGTH',
    translationValue: 'Das Passwort muss mindestens drei Zeichen enthalten.'
  },
  {
    translationKey: 'USER_MANAGEMENT_REPASSWORD_EMPTY',
    translationValue: 'Das Wiederholungspasswort darf nicht leer sein.'
  },
  {
    translationKey: 'USER_MANAGEMENT_REPASSWORD_NOT_MATCH',
    translationValue: 'Passwort und Wiederholungspasswort müssen übereinstimmen.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USERNAME_EMPTY',
    translationValue: 'Das Benutzernamenfeld darf nicht leer sein.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_INVALID',
    translationValue: 'Benutzer ist ungültig.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_EXPIRED',
    translationValue: 'Der Bestätigungscode ist abgelaufen.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_INVALID',
    translationValue: 'Der Bestätigungscode ist ungültig.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_EMPTY',
    translationValue: 'Bitte geben Sie den Bestätigungscode ein.'
  },
  {
    translationKey: 'USER_MANAGEMENT_OTP_RETRY',
    translationValue: 'Bitte warten Sie zwei Minuten.'
  },
  {
    translationKey: 'PAYMENT_IP_INVALID',
    translationValue: 'Ihre IP-Adresse ist ungültig.'
  },
  {
    translationKey: 'PAYMENT_REGION_INVALID',
    translationValue: 'Der Rabattcode ist in Ihrer Region nicht gültig.'
  },
  {
    translationKey: 'PAYMENT_CODE_EXPIRED',
    translationValue: 'Der Rabattcode ist abgelaufen.'
  },
  {
    translationKey: 'PAYMENT_CODE_UNAVAILABLE',
    translationValue: 'Der Rabattcode ist nicht mehr verfügbar.'
  },
  {
    translationKey: 'PAYMENT_CODE_USED',
    translationValue: 'Dieser Rabattcode wurde bereits verwendet.'
  },
  {
    translationKey: 'CORE_AI_TOKEN_AMOUNT',
    translationValue: 'Sie haben nicht genug Tokens.'
  },
  {
    translationKey: 'CORE_AI_ROLE_DESCRIPTION_INVALID',
    translationValue: 'Es gibt keine Rollenbeschreibung mit dieser ID.'
  },
  {
    translationKey: 'CORE_AI_PROMPT_ID_INVALID',
    translationValue: 'Es gibt kein Prompt mit dieser ID.'
  },
  {
    translationKey: 'CORE_AI_FEEDBACK_NOT_FOUND',
    translationValue: 'Feedback nicht gefunden.'
  },
  {
    translationKey: 'CORE_FILE_NOT_ENOUGH_SPACE',
    translationValue: 'Nicht genügend Speicherplatz vorhanden.'
  },
  {
    translationKey: 'CORE_FAVORITE_CATEGORY_EXISTS',
    translationValue: 'Eine Kategorie mit diesem Namen existiert bereits!'
  },
  {
    translationKey: 'CORE_FAVORITE_CATEGORY_NOT_FOUND',
    translationValue: 'Kategorie nicht gefunden!'
  },
  {
    translationKey: 'CORE_FAVORITE_ITEM_EXISTS',
    translationValue: 'Dieses Element existiert bereits in der ausgewählten Kategorie.'
  },
  {
    translationKey: 'CORE_MEMORY_BOX_CARD_EXISTS',
    translationValue: 'Diese Karte existiert bereits.'
  },
  {
    translationKey: 'CORE_MEMORY_BOX_CATEGORY_EXISTS',
    translationValue: 'Eine Karteikarten-Kategorie mit diesem Namen existiert bereits.'
  },
  {
    translationKey: 'CORE_MEMORY_NOTEPAD_TITLE_EXISTS',
    translationValue: 'Der Titel existiert bereits! Bitte wählen Sie einen anderen Titel.'
  },
  {
    translationKey: 'CORE_DICTIONARY_WORD_NOT_FOUND',
    translationValue: 'Das Wort wurde in unserer Bibliothek nicht gefunden.'
  },
  {
    translationKey: 'PAYMENT_CODE_INVALID',
    translationValue: 'Der Rabattcode ist falsch. Bitte überprüfen Sie ihn und versuchen Sie es erneut.'
  },
  {
    translationKey: 'OOPS_SOMETHING_WENT_WRONG',
    translationValue: 'Hoppla! Etwas ist schiefgelaufen.'
  },
  {
    translationKey: 'CORE_TURN_OFF_LEVEL_INVALID',
    translationValue: 'Frühere Level können nicht aktiviert werden.'
  },
  {
    translationKey: 'CORE_TURN_OFF_UNIT_INVALID',
    translationValue: 'Frühere Einheiten können nicht deaktiviert werden.'
  },
  {
    translationKey: 'CORE_TURN_ON_UNIT_INVALID',
    translationValue: 'Frühere Einheiten können nicht aktiviert werden.'
  }
]
