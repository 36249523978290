import React, { useContext, useEffect, useState } from 'react'
import Header from '../components/Header/Header'
import AudioPlayer from '../components/AudioPlayer/AudioPlayer'
import { useSelector } from 'react-redux'
import { fetchArchive } from '../redux/features/archiveSlice'
import moment from 'moment'
import makeUrlByHashValue from '../operations/makeUrlByHashValue'
import { destroyArchiveFile } from '../redux/features/conversationSlice'
import ConfirmationModal from '../components/ConfirmationModal/ConfirmationModal'
import colorsContext from '../contexts/ColorsContext'
import FadeMotion from '../layouts/Motion/FadeMotion'
import HelpButton from '../components/HelpButton/HelpButton'
import { pageGuidEnums } from '../enums/pageGuideEnums/pageGuideEnums'
import { getTimestampMilliSeconds } from '../util/utility'
import TranslatedText from '../components/TranslatedText/TranslatedText'
import useEnhancedDispatch from '../hooks/useDispatchWrapper'

const Archive = () => {
  const states = useSelector(state => state.archive)
  const { colors } = useContext(colorsContext)
  const { enhancedDispatch } = useEnhancedDispatch()

  const [removeAudioModalIsOpen, setRemoveAudioModalIsOpen] = useState(false)
  const [selectedAudio, setSelectedAudio] = useState(null)

  useEffect(() => {
    enhancedDispatch(fetchArchive)
  }, [])

  const renderAudios = () => {
    return states.data.map((item, i) => {
      let date = new Date(getTimestampMilliSeconds(item.createDate))
      return (
        <div className='col-12 col-lg-6 mb-4' key={i}>
          <AudioPlayer
            isArchive
            audioId={item.id}
            audioTitle={item.fileTitle}
            audioName={item.userTitle}
            audioDate={moment(date).format('YYYY.MM.DD')}
            audioSrc={makeUrlByHashValue(item.hashValue, item.extension)}
            onClickRemove={() => {
              setRemoveAudioModalIsOpen(true)
              setSelectedAudio(item)
            }}
          />
        </div>
      )
    })
  }

  return (
    <FadeMotion data-jsx='Archive'>
      <div className='minh-100vh' style={{ background: colors['C183'] }}>
        <Header
          withBackButton
          iconsColor={colors['C167']}
          title='Archive'
          backgroundColor={colors['C14']}
          titleColor={colors['C167']}
        />
        <div className='container-fluid mt-3'>
          <div className='d-flex justify-content-end me-3 me-md-5'>
            <HelpButton color={colors['C2']} pageType={pageGuidEnums.CONVERSATION_ARCHIVE_MAIN_PAGE} />
          </div>
        </div>
        <div className='container py-3'>
          <div className='row'>
            {states.loading && (
              <div className='text-center mt-4'>
                <span className='spinner-border' />
              </div>
            )}
            {states.data != null && renderAudios()}
          </div>
        </div>
      </div>
      {removeAudioModalIsOpen && (
        <ConfirmationModal
          closeModal={() => setRemoveAudioModalIsOpen(false)}
          text={<TranslatedText translationKey={'delete.archive.modal'} />}
          cancelText={<TranslatedText translationKey={'cancel'} />}
          confirmText={<TranslatedText translationKey={'delete'} />}
          onConfirm={() => {
            enhancedDispatch(destroyArchiveFile, {
              fileId: selectedAudio.id,
              unitId: selectedAudio.unitId
            })
          }}
        />
      )}
    </FadeMotion>
  )
}

export default Archive
