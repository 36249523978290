import filesAndFoldersMapper from '../../services/mapper/notepad/filesAndFoldersMapper'
import { notepadAddFolder } from '../../services/cloudServices'

const addFolderUseCase = async ({ parentFolderId, title, color }) => {
  const result = await notepadAddFolder({ parentFolderId, title, color })

  return filesAndFoldersMapper(result)
}

export default addFolderUseCase
