import { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { hideLoader } from './helper'
import { getAllColors } from './colors/colors'
import { ColorsProvider } from './contexts/ColorsContext'
import { isEmpty, toNumber } from 'lodash'
import AppRoutes from './routes/AppRoutes'
import Sidebar from './components/Sidebar'
import 'overlayscrollbars/overlayscrollbars.css'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import setCookie from './operations/setCookie'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-circular-progressbar/dist/styles.css'
import './styles/style.scss'
import GlobalModals from './components/GlobalModals/GlobalModals'
import { LogProvider } from './contexts/LogContext'
import SystemLog from './log/Log'
import { KUALA, MYSTERY, TANGERINE, TWILIGHT } from './enums/base/themeEnums'
import { useDispatch } from 'react-redux'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import { setSelectedTranslationLanguage } from './redux/features/translationSlice'
import { translationsLanguagesData } from './enums/languageEnums/languageEnums'
import useAppUser from './hooks/useAppUser'
import useLocalStorage from './hooks/useLocalStorage'
import * as Sentry from '@sentry/react'
import { initGA } from './components/analytics'
import FirstTimeHelpActions from './atomicComponents/templates/FirstTimeHelpActions/FirstTimeHelpActions'
import { TourProvider } from '@reactour/tour'
import VersionUpdateModal from './components/VersionUpdateModal/VersionUpdateModal'
import PhraseOptionsModal from './atomicComponents/templates/modals/PhraseOptionsModal'
import NetworkStatus from './atomicComponents/molecules/NetworkStatus/NetworkStatus'
import { retryFailedRequests } from './apis/retryFailedRequest'
import { isMobileSafari } from 'react-device-detect'
import { setCurrentOpenModal } from './redux/features/modalSlice'
import { globalModalEnums } from './enums/globalEnums/globalEnums'
import { errorTranslationsLanguagesData } from './enums/errorLanguageEnums/errorLanguageEnums'
import CelebrateUnitCompletion from './atomicComponents/templates/CelebrateUnitCompletion/CelebrateUnitCompletion'
import AppInRouter from 'components/AppInRouter'
import InAppNotifications from 'atomicComponents/templates/InAppNotifications'
import { v4 } from 'uuid'
import { getFirebaseMessagingToken } from 'firebaseStarter'
import { setAppUser, updateNotificationReceiver } from 'redux/features/appUserSlice'
import PathListener from 'components/PathListener'
import { LocalStorageKeys } from 'types/global'
import { useAppSelector } from 'hooks/useAppSelector'
import { isValidLanguage } from 'util/isValidLanguage'
import userActionEnums from 'enums/userActionEnums/userActionEnums'
import { setNativeLanguage } from 'redux/features/nativeLanguageSlice'
import { finishFirstTimeAction } from 'redux/features/firstTimeActionSlice'
import { setLoggedInWithLanguage } from 'redux/features/authenticationSlice'

const App = () => {
  const [colors, setColors] = useState({})
  const [theme, setTheme] = useState('')
  const { loggedInWithLanguage } = useAppSelector(state => state.authentication)
  const [log] = useState(new SystemLog())
  // const pageVisibilityHidden = usePageVisibility()
  const [deviceId] = useLocalStorage('deviceId', v4())
  const [lsLanguage, setLsLanguage] = useLocalStorage(LocalStorageKeys.LANGUAGE, null)
  const { appUserData } = useAppUser()
  const [fcmToken, setFcmToken] = useLocalStorage('fcmToken', null)
  const [addedToHomeScreen, setAddedToHomeScreen] = useLocalStorage('addedToHomeScreen', false)
  const [displayAddToHomeScreenGuideTime, setDisplayAddToHomeScreenGuideTime] = useLocalStorage(
    'displayAddToHomeScreenGuideTime'
  )
  const dispatch = useDispatch()

  useEffect(() => {
    document.documentElement.setAttribute('translate', 'no')
  }, [])

  const checkAddToHomeScreen = () => {
    if (isMobileSafari && !addedToHomeScreen) {
      if (window.navigator.standalone == true) {
        setAddedToHomeScreen(true)
        setDisplayAddToHomeScreenGuideTime(null)
      } else {
        const currentTime = new Date().getTime()
        const limitDuration = 1000 * 60 * 60 * 24 * 30 // Milli-seconds to 30 days

        if (
          displayAddToHomeScreenGuideTime &&
          currentTime - toNumber(displayAddToHomeScreenGuideTime) < limitDuration
        ) {
          return
        } else {
          dispatch(setCurrentOpenModal(globalModalEnums.addToHomeScreenGuide))
          setDisplayAddToHomeScreenGuideTime(new Date().getTime())
        }
      }
    }
  }

  const setupColors = async theme => {
    const colorsData = await getAllColors(theme)
    setColors(colorsData)
    document.body.style.background = colorsData['C183']
  }

  useEffect(() => {
    hideLoader()

    // Initialize Google Analytics
    initGA()

    // // Log the initial page view
    // logPageView()

    // // Add event listener to track page changes
    // const handlePageChange = () => {
    //   logPageView()
    // }

    retryFailedRequests()

    const modal = document.getElementById('modal')

    const handleModalContentChange = () => {
      const pageLayerEl = document.getElementById('page-layer')
      document.body.style.overflow =
        modal?.childNodes?.length > 0 || pageLayerEl?.childNodes?.length > 0 ? 'hidden' : null
    }

    checkAddToHomeScreen()

    // Listen for changes in the URL (react-router example)
    // window.addEventListener('hashchange', handlePageChange);

    modal.addEventListener('modalContentChanged', handleModalContentChange)

    return () => {
      // Clean up event listener on component unmount
      // window.removeEventListener('hashchange', handlePageChange);
      modal.removeEventListener('modalContentChanged', handleModalContentChange)
    }
  }, [])

  useEffect(() => {
    // Re-fetching FCM token and update it
    if (deviceId && appUserData && 'Notification' in window && Notification.permission === 'granted') {
      getFirebaseMessagingToken().then(givenToken => {
        if (givenToken && givenToken !== fcmToken) {
          setFcmToken(givenToken)
          dispatch(updateNotificationReceiver({ registrationToken: givenToken, deviceId }))
        }
      })
    }
  }, [deviceId, appUserData])

  useEffect(() => {
    const settingNativeLanguageKey = appUserData?.setting?.nativeLanguage

    if (settingNativeLanguageKey && errorTranslationsLanguagesData[settingNativeLanguageKey]) {
      localStorage.setItem('selectedErrorTranslationLanguageKey', settingNativeLanguageKey)
    } else if (lsLanguage && errorTranslationsLanguagesData[lsLanguage]) {
      localStorage.setItem('selectedErrorTranslationLanguageKey', lsLanguage)
    } else {
      // If no native language is set, default to English
      localStorage.setItem('selectedErrorTranslationLanguageKey', 'ENGLISH')
    }
  }, [appUserData?.setting?.nativeLanguage, lsLanguage])

  useEffect(() => {
    document.querySelector('meta[name="theme-color"]').setAttribute('content', colors['C54'])
  }, [colors])

  useEffect(() => {
    if (appUserData?.setting?.theme !== theme) {
      let userTheme = TANGERINE
      if (appUserData?.setting?.theme) {
        userTheme = appUserData?.setting?.theme
        setCookie('theme', appUserData?.setting?.theme, 24 * 7)
      } else {
        setCookie('theme', TANGERINE, 24 * 7)
      }

      setTheme(userTheme)
      setupColors(userTheme)

      if (userTheme === KUALA || userTheme === TWILIGHT || userTheme === MYSTERY) {
        document.documentElement.classList.add('tw-dark')
      } else {
        document.documentElement.classList.remove('tw-dark')
      }
    }
  }, [appUserData?.setting?.theme])

  useEffect(() => {
    let languageData = translationsLanguagesData[appUserData?.setting?.nativeLanguage]
    if (!languageData) {
      languageData = translationsLanguagesData[lsLanguage]
    }
    if (languageData) {
      dispatch(setSelectedTranslationLanguage(languageData))
    }
  }, [appUserData?.setting?.nativeLanguage, lsLanguage])

  useEffect(() => {
    if (
      appUserData &&
      loggedInWithLanguage &&
      isValidLanguage(loggedInWithLanguage) &&
      appUserData?.action?.currentAction !== userActionEnums.setNativeLanguage &&
      loggedInWithLanguage !== appUserData?.setting?.nativeLanguage
    ) {
      // Post selected native language (service)
      // @ts-ignore
      dispatch(setNativeLanguage({ language: loggedInWithLanguage })).then(res => {
        dispatch(finishFirstTimeAction(userActionEnums.setNativeLanguage))
        // Update appUserData state
        dispatch(setAppUser(res.payload))
        dispatch(setLoggedInWithLanguage(null))
        setLsLanguage('')
      })
    }
  }, [appUserData, loggedInWithLanguage])

  // Set sentry user data
  useEffect(() => {
    Sentry.setUser({
      id: appUserData?.info?.id
      // username: appUserData?.info?.username // Sending username can put users privacy at risk
    })
  }, [appUserData?.info?.id])

  if (!isEmpty(colors)) {
    return (
      <>
        <ColorsProvider value={{ colors, setColors, theme, setTheme }}>
          <LogProvider value={{ log }}>
            <TourProvider
              steps={[]}
              showBadge={false}
              showNavigation={false}
              showPrevNextButtons={false}
              showDots={false}
              styles={{
                popover: base => ({
                  ...base,
                  borderRadius: 5,
                  border: '1px solid #E98020',
                  fontWeight: 200,
                  fontFamily: '"Roboto Flex", "Sahel", "Segoe UI", sans-serif',
                  padding: '16px 20px'
                }),
                close: base => ({ ...base, left: 'auto', right: 8, top: 8, color: '#C1C7C9' })
              }}
            >
              <BrowserRouter>
                <PathListener />
                <AppInRouter />
                <ScrollToTop />
                <NetworkStatus />
                {/*{Boolean(authToken) && !Boolean(userData) && <Navigate to="/"/>}*/}
                <AppRoutes />
                <ToastContainer />
                <GlobalModals />
                <FirstTimeHelpActions />
                <VersionUpdateModal />
                <Sidebar />
                <PhraseOptionsModal />
                <CelebrateUnitCompletion />
                <InAppNotifications />
              </BrowserRouter>
            </TourProvider>
          </LogProvider>
        </ColorsProvider>
      </>
    )
  } else {
    return null
  }
}

export default App
