import flashCardsMapper from '../../services/mapper/memoryBox/flashCardsMapper'
import { searchMemoryBoxFlashCard } from '../../services/cloudServices'

const searchFlashCardUseCase = async searchCriteria => {
  const result = await searchMemoryBoxFlashCard(searchCriteria)

  return flashCardsMapper(result)
}

export default searchFlashCardUseCase
