export default [
  {
    translationKey: 'GATEWAY_CAPTCHA_VALIDATION_FAILED',
    translationValue: 'Verifica Captcha non riuscita.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_CREATE_FAILED',
    translationValue: 'Impossibile creare l’utente.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_NOT_FOUND',
    translationValue: 'Il nome utente non esiste.'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_INCORRECT',
    translationValue: 'La password non è corretta!'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_EMPTY',
    translationValue: 'Il campo della password non può essere vuoto.'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_LENGTH',
    translationValue: 'La password deve contenere almeno tre caratteri.'
  },
  {
    translationKey: 'USER_MANAGEMENT_REPASSWORD_EMPTY',
    translationValue: 'Il campo di conferma della password non può essere vuoto.'
  },
  {
    translationKey: 'USER_MANAGEMENT_REPASSWORD_NOT_MATCH',
    translationValue: 'La password e la conferma devono corrispondere.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USERNAME_EMPTY',
    translationValue: 'Il campo del nome utente non può essere vuoto.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_INVALID',
    translationValue: 'L’utente non è valido.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_EXPIRED',
    translationValue: 'Il codice di conferma è scaduto.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_INVALID',
    translationValue: 'Il codice di conferma non è valido.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_EMPTY',
    translationValue: 'Inserisci il codice di conferma.'
  },
  {
    translationKey: 'USER_MANAGEMENT_OTP_RETRY',
    translationValue: 'Attendi due minuti.'
  },
  {
    translationKey: 'PAYMENT_IP_INVALID',
    translationValue: 'Il tuo indirizzo IP non è valido.'
  },
  {
    translationKey: 'PAYMENT_REGION_INVALID',
    translationValue: 'Il codice sconto non è valido nella tua regione.'
  },
  {
    translationKey: 'PAYMENT_CODE_EXPIRED',
    translationValue: 'Il codice sconto è scaduto.'
  },
  {
    translationKey: 'PAYMENT_CODE_UNAVAILABLE',
    translationValue: 'Il codice sconto non è più disponibile.'
  },
  {
    translationKey: 'PAYMENT_CODE_USED',
    translationValue: 'Questo codice sconto è già stato utilizzato.'
  },
  {
    translationKey: 'CORE_AI_TOKEN_AMOUNT',
    translationValue: 'Non hai abbastanza token.'
  },
  {
    translationKey: 'CORE_AI_ROLE_DESCRIPTION_INVALID',
    translationValue: 'Nessuna descrizione del ruolo trovata per questo ID.'
  },
  {
    translationKey: 'CORE_AI_PROMPT_ID_INVALID',
    translationValue: 'Nessun prompt trovato per questo ID.'
  },
  {
    translationKey: 'CORE_AI_FEEDBACK_NOT_FOUND',
    translationValue: 'Feedback non trovato.'
  },
  {
    translationKey: 'CORE_FILE_NOT_ENOUGH_SPACE',
    translationValue: 'Spazio di archiviazione insufficiente.'
  },
  {
    translationKey: 'CORE_FAVORITE_CATEGORY_EXISTS',
    translationValue: 'Esiste già una categoria con questo nome!'
  },
  {
    translationKey: 'CORE_FAVORITE_CATEGORY_NOT_FOUND',
    translationValue: 'Categoria non trovata!'
  },
  {
    translationKey: 'CORE_FAVORITE_ITEM_EXISTS',
    translationValue: 'Questo elemento esiste già nella categoria selezionata.'
  },
  {
    translationKey: 'CORE_MEMORY_BOX_CARD_EXISTS',
    translationValue: 'Questa scheda esiste già.'
  },
  {
    translationKey: 'CORE_MEMORY_BOX_CATEGORY_EXISTS',
    translationValue: 'Esiste già una categoria di flashcard con questo nome.'
  },
  {
    translationKey: 'CORE_MEMORY_NOTEPAD_TITLE_EXISTS',
    translationValue: 'Il titolo esiste già! Scegli un altro titolo.'
  },
  {
    translationKey: 'CORE_DICTIONARY_WORD_NOT_FOUND',
    translationValue: 'La parola non è stata trovata nella nostra libreria.'
  },
  {
    translationKey: 'PAYMENT_CODE_INVALID',
    translationValue: 'Il codice sconto non è corretto. Controllalo e riprova.'
  },
  {
    translationKey: 'OOPS_SOMETHING_WENT_WRONG',
    translationValue: 'Ops! Qualcosa è andato storto.'
  },
  {
    translationKey: 'CORE_TURN_OFF_LEVEL_INVALID',
    translationValue: 'Non puoi attivare i livelli precedenti.'
  },
  {
    translationKey: 'CORE_TURN_OFF_UNIT_INVALID',
    translationValue: 'Non puoi disattivare le unità precedenti.'
  },
  {
    translationKey: 'CORE_TURN_ON_UNIT_INVALID',
    translationValue: 'Non puoi attivare le unità precedenti.'
  }
]
