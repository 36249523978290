import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { postHelpAfterSetNativeLanguageAction, postHelpAfterSetThemeAction } from '../../services/cloudServices'

const initialState = {
  loading: false,
  data: null,
  error: ''
}

export const updateHelpAfterSetNativeLanguageAction = createAsyncThunk(
  'helpAction/updateHelpAfterSetNativeLanguageAction',
  () => {
    return postHelpAfterSetNativeLanguageAction(null)
  }
)

export const updateHelpAfterSetThemeAction = createAsyncThunk('helpAction/updateHelpAfterSetThemeAction', () => {
  return postHelpAfterSetThemeAction(null)
})

const helpActionSlice = createSlice({
  name: 'helpActionSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(updateHelpAfterSetNativeLanguageAction.pending, state => {
      state.loading = true
    })
    builder.addCase(updateHelpAfterSetNativeLanguageAction.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(updateHelpAfterSetNativeLanguageAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(updateHelpAfterSetThemeAction.pending, state => {
      state.loading = true
    })
    builder.addCase(updateHelpAfterSetThemeAction.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(updateHelpAfterSetThemeAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const {} = helpActionSlice.actions

export default helpActionSlice.reducer
