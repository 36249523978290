import conversationMapper from '../../services/mapper/conversationSection/conversationMapper'
import { fetchSection } from '../../services/cloudServices'

const conversationUseCase = async ({ unit }) => {
  const response = await fetchSection(
    {
      unit: unit,
      section: 'CONVERSATION'
    }
  )

  return conversationMapper(response.data)
}

export default conversationUseCase
