import accentsMapper from '../../services/mapper/accents/accentsMapper'
import { fetchAccents } from '../../services/cloudServices'

const accentsUseCase = async () => {
  const response = await fetchAccents(null)

  return accentsMapper(response)
}

export default accentsUseCase
