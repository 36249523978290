import { useEffect } from 'react'
import * as Sentry from '@sentry/react'

const ChunkErrorPage = () => {
  useEffect(() => {
    Sentry.captureException(new Error('An unexpected chunk error occurred in this page'))
  }, [])

  return (
    <div className='tw-flex tw-flex-col tw-gap-8 tw-justify-center tw-items-center tw-h-screen-dvh tw-w-full tw-bg-neutral-950'>
      <h2 className='tw-text-xl tw-text-white tw-font-thin'>An unexpected error occurred in this page :(</h2>
      <div>
        <a href='/' className='tw-text-white hover:tw-underline hover:tw-text-white'>
          Back to home
        </a>
      </div>
    </div>
  )
}

export default ChunkErrorPage
