import flashCardsMapper from '../../services/mapper/memoryBox/flashCardsMapper'
import { ASC, HISTORY } from '../../enums/globalEnums/globalEnums'
import { fetchAllUnitHistoryMemoryBoxFlashCards } from '../../services/cloudServices'

const getAllUnitHistoryFlashCardUseCase = async ({ categoryId, searchTerm, sortBy, sortKey, unitId, isVocab }) => {
  const result = await fetchAllUnitHistoryMemoryBoxFlashCards(
    {
      boxType: HISTORY,
      categoryId: categoryId,
      frontText: searchTerm,
      sortAsc: sortBy === ASC,
      sortBy: sortKey,
      unitId: unitId,
      isVocab: isVocab
    }
  )

  return flashCardsMapper(result)
}

export default getAllUnitHistoryFlashCardUseCase
