import wordDataMapper from '../../services/mapper/wordData/wordDataMapper'
import { transliterate } from 'transliteration'
import * as Sentry from '@sentry/react'
import { fetchWordDictionary } from '../../services/cloudServices'

const wordDataUseCase = async ({ phrase }) => {
  phrase = transliterate(phrase)

  const data = await fetchWordDictionary({ phrase })

  const wordData = data?.[0]

  if (!wordData?.id) {
    Sentry.captureMessage(`Word data from vocabulary service is empty for: ${phrase}`, {
      level: 'warning',
      extra: { wordData, url: window.location.href }
    })
  }

  return wordDataMapper(data, phrase)
}

export default wordDataUseCase
