import settingStudyReminderMapper from '../../services/mapper/setting/settingStudyReminderMapper'
import setSettingStudyReminderMapper from '../../services/mapper/setting/setSettingStudyReminderMapper'
import { postStudyReminderSettings } from '../../services/cloudServices'

const setSettingStudyReminderUseCase = async ({ param }) => {
  const data = await postStudyReminderSettings(
    { param: setSettingStudyReminderMapper({ param }) }
  )
  return settingStudyReminderMapper(data)
}

export default setSettingStudyReminderUseCase
