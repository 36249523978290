import aiChatRoleDescriptionMapper from '../../services/mapper/aiChatRoleDescription/aiChatRoleDescriptionMapper'
import { fetchAiChatRoleDescriptions } from '../../services/cloudServices'

const aiChatRoleDescriptionUseCase = async ({ unitId, promptType }) => {
  const result = await fetchAiChatRoleDescriptions({ unitId, promptType })

  return aiChatRoleDescriptionMapper(result)
}

export default aiChatRoleDescriptionUseCase
