import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import setSettingStudyReminderUseCase from '../../useCase/setting/setSettingStudyReminderUseCase'
import {
  postAddressSettings,
  postEmailSettings,
  postFirstNameSettings, postLastNameSettings, postPhoneNumberSettings,
  postPrivacySettings,
  updateNotificationSettings,
  updateUserAvatar,
} from '../../services/cloudServices'

const initialState = {
  loading: false,
  setUserAvatarLoading: false,
  privacySettings: null,
  notificationSettings: null,
  error: ''
}

export const setUserAvatar = createAsyncThunk('setting/setUserAvatar', ({ avatarType }) => {
  return updateUserAvatar({ avatarType })
})

export const setPrivacySettings = createAsyncThunk(
  'setting/setPrivacySettings',
  ({ onlineGame, profilePhoto, identifyAs }) => {
    return postPrivacySettings({ onlineGame, profilePhoto, identifyAs })
  }
)

export const setNotificationSettings = createAsyncThunk('setting/setNotificationSettings', ({ values }) => {
  return updateNotificationSettings({ values })
})

export const setStudyReminderSettings = createAsyncThunk('setting/setStudyReminderSettings', ({ param }) =>
  setSettingStudyReminderUseCase({ param })
)

export const setFirstNameSettings = createAsyncThunk('setting/setFirstNameSettings', ({ firstName }) => {
  return postFirstNameSettings({ firstName })
})

export const setLastNameSettings = createAsyncThunk('setting/setLastNameSettings', ({ lastName }) => {
  return postLastNameSettings({ lastName })
})

export const setPhoneNumberSettings = createAsyncThunk('setting/setPhoneNumberSettings', ({ phoneNumber }) => {
  return postPhoneNumberSettings({ phoneNumber })
})

export const setEmailSettings = createAsyncThunk('setting/setEmailSettings', ({ email }) => {
  return postEmailSettings({ email })
})

export const setAddressSettings = createAsyncThunk('setting/setAddressSettings', ({ address }) => {
  return postAddressSettings({ address })
})

const settingSlice = createSlice({
  name: 'setting',
  initialState,
  extraReducers: builder => {
    // set Privacy setting
    builder.addCase(setPrivacySettings.pending, state => {
      state.loading = true
    })
    builder.addCase(setPrivacySettings.fulfilled, (state, action) => {
      state.loading = false
      state.privacySettings = action.payload
    })
    builder.addCase(setPrivacySettings.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    // set Study Reminder Settings
    builder.addCase(setStudyReminderSettings.pending, state => {
      state.loading = true
    })
    builder.addCase(setStudyReminderSettings.fulfilled, (state, action) => {
      state.loading = false
      state.studyReminderSettings = action.payload
    })
    builder.addCase(setStudyReminderSettings.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    // set user avatar setting
    builder.addCase(setUserAvatar.pending, state => {
      state.setUserAvatarLoading = true
    })
    builder.addCase(setUserAvatar.fulfilled, (state, action) => {
      state.setUserAvatarLoading = false
    })
    builder.addCase(setUserAvatar.rejected, (state, action) => {
      state.setUserAvatarLoading = false
      state.error = action.error.message
    })
  }
})

export const {} = settingSlice.actions

export default settingSlice.reducer
