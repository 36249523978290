import { SAMAN, ZARINPAL } from '../../enums/payment/gatewaysEnums'
import { subscriptionSamanPlan, subscriptionZarinPalPlan } from '../../services/cloudServices'

const subscriptionIranPalUseCase = async ({ asRenewal, discountCode, planId, psp, purchaseType }) => {
  let result
  if (psp === ZARINPAL) {
    result = await subscriptionZarinPalPlan({ asRenewal, discountCode, planId, psp, purchaseType })
  } else if (psp === SAMAN) {
    result = await subscriptionSamanPlan({ asRenewal, discountCode, planId, psp, purchaseType })
  }

  return { ...result, psp }
}

export default subscriptionIranPalUseCase
