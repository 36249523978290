import aiPackagesMapper from 'services/mapper/payment/aiPackagesMapper'
import { fetchAiPackages } from 'services/cloudServices'

const getAiPackageUseCase = async () => {
  const result = await fetchAiPackages(null)

  return aiPackagesMapper(result)
}

export default getAiPackageUseCase
