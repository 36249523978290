declare global {
  interface Window {
    turnstile?: typeof turnstile
    dataLayer: any[]
  }
}

export enum LocalStorageKeys {
  USER_TOKEN = 'user_token',
  THEME = 'theme',
  LANGUAGE = 'language',
  UTM_SOURCE = 'utm_source'
}
