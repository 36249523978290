import aboutMapper from '../../services/mapper/about/aboutMapper'
import { fetchAboutDescription } from '../../services/cloudServices'

const aboutUseCase = async () => {
  const result = await fetchAboutDescription(null)

  return aboutMapper(result)
}

export default aboutUseCase
