import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { isArray } from 'lodash'
import conversationUseCase from '../../useCase/conversationSectionUseCase/conversationUseCase'
import { playCheckSound } from '../../util/playCheckSound/playCheckSound'
import { AnswerStatusEnums } from '../../enums/globalEnums/globalEnums'
import matchingExactUseCase from '../../useCase/matchingExactUseCase.js/matchingExactUseCase'
import matchingExactHintUseCase from '../../useCase/matchingExactUseCase.js/matchingExactHintUseCase'
import { baseAsyncThunk } from '../baseAsyncThunk'
import {
  deleteArchiveFile,
  postArchiveRolePlayService,
  saveArchiveFileService,
  spell
} from '../../services/cloudServices'

const initialState = {
  loading: false,
  isChecking: false,
  data: {},
  page: 'intro',
  error: '',
  typeMethod: 'keyboard', // "keyboard", "voice"
  memorizeCharacterSelected: false,
  memorizeSelectedCharacters: [],
  rolePlayCharacterSelected: false,
  rolePlaySelectedCharacter: null,
  playlist: [],
  characters: [],
  userAnswers: [],
  speechDialogNumber: null,
  rolePlayActiveDialog: null,
  rolePlayShowText: false,
  rolePlayOnDialog: null,
  rolePlayFinished: false,
  recording: false,
  recordModalIsOpen: false,
  spellPending: false,
  voiceStr: '',
  archiveLoading: false,
  archiveResponse: null,
  finalAudio: null
}

export const fetchConversation = baseAsyncThunk('conversation/fetchConversation', ({ unit }) => {
  return conversationUseCase({ unit })
})

export const checkConversationMemorize = baseAsyncThunk(
  'conversation/checkConversationMemorize',
  ({ unit, matchCases, dialogNumber, trackerNumber, isVoiceRecognition }) => {
    return matchingExactUseCase({
      matchType: 'EXACT_NON_CASE_SENSITIVE',
      inputType: 'SENTENCE_WORD_BY_WORD_MATCH',
      sectionType: 'CONVERSATION',
      toolsCheck: false,
      unitId: unit,
      trackerNumber,
      matchCases,
      isVoiceRecognition,
      dialogNumber
    })
  }
)

export const getConversationMemorizeHint = baseAsyncThunk(
  'conversation/getConversationMemorizeHint',
  ({ unit, matchCases, dialogNumber, trackerNumber }) => {
    return matchingExactHintUseCase({
      matchType: 'EXACT_NON_CASE_SENSITIVE',
      inputType: 'SENTENCE_WORD_BY_WORD_MATCH',
      sectionType: 'CONVERSATION',
      toolsCheck: false,
      unitId: unit,
      trackerNumber,
      matchCases,
      dialogNumber
    })
  }
)

export const speechToText = createAsyncThunk('conversation/speechToText', ({ formData, callbackData }) => {
  return spell({
    formData,
    callbackData
  })
})

export const postArchiveRolePlay = baseAsyncThunk('conversation/postArchiveRolePlay', ({ formData }) => {
  return postArchiveRolePlayService({
    formData
  })
})

export const saveArchiveFile = createAsyncThunk('conversation/saveArchiveFile', ({ hashValue, plainValue }) => {
  return saveArchiveFileService({
    hashValue,
    plainValue
  })
})

export const destroyArchiveFile = baseAsyncThunk('conversation/destroyArchiveFile', ({ fileId, unitId }) => {
  return deleteArchiveFile({
    fileId,
    sectionType: 'CONVERSATION',
    unitId
  })
})

const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload
    },
    setTypeMethod: (state, action) => {
      state.typeMethod = action.payload
    },
    setMemorizeCharacterSelected: (state, action) => {
      state.memorizeCharacterSelected = action.payload
    },
    setMemorizeSelectedCharacters: (state, action) => {
      let index = state.memorizeSelectedCharacters.indexOf(action.payload)

      if (index === -1) {
        state.memorizeSelectedCharacters = [...state.memorizeSelectedCharacters, action.payload]
      } else if (state.memorizeSelectedCharacters.length >= 2) {
        state.memorizeSelectedCharacters = state.memorizeSelectedCharacters.filter(c => c !== action.payload)
      }
    },
    setRolePlaySelectedCharacter: (state, action) => {
      state.rolePlaySelectedCharacter = action.payload
    },
    setRolePlayCharacterSelected: (state, action) => {
      state.rolePlayCharacterSelected = action.payload
    },
    setUserAnswers: (state, action) => {
      state.userAnswers = action.payload
    },
    setInputValue: (state, action) => {
      // Find intended dialog
      const dialogAnswerObj = state.userAnswers.find(obj => obj.dialogNumber === action.payload.dialogNumber)

      if (dialogAnswerObj) {
        if (action.value === '') {
          // If input value is null, remove from userAnswers state
          state.userAnswers = state.userAnswers.filter(obj => obj.dialogNumber !== action.payload.dialogNumber)
        } else {
          // If input value is not null update userAnswers state
          state.userAnswers = [...state.userAnswers].map(obj => {
            if (obj.dialogNumber === action.payload.dialogNumber) {
              return { ...obj, sentence: action.payload.value, bySpeech: Boolean(action.payload.bySpeech) }
            }
            return obj
          })
        }
      } else {
        // If there is not any object related for this dialog create object for userAnswers state
        state.userAnswers = [
          ...state.userAnswers,
          {
            dialogNumber: action.payload.dialogNumber,
            sentence: action.payload.value,
            usedHint: false,
            checked: false,
            checkResult: null,
            bySpeech: Boolean(action.payload.bySpeech)
          }
        ]
      }
    },
    toggleLock: (state, action) => {
      const dialog = state.userAnswers.find(obj => obj.dialogNumber === action.payload.dialogNumber)
      if (dialog) {
        state.userAnswers = [...state.userAnswers].map(item => {
          if (item.dialogNumber === action.payload.dialogNumber) {
            return {
              ...item,
              unlocked: action.payload.value
            }
          }
          return item
        })
      }
    },
    setRolePlayActiveDialog: (state, action) => {
      state.rolePlayActiveDialog = action.payload
    },
    setRolePlayShowText: (state, action) => {
      state.rolePlayShowText = action.payload
    },
    setRolePlayOnDialog: (state, action) => {
      state.rolePlayOnDialog = action.payload
    },
    setRolePlayFinished: (state, action) => {
      state.rolePlayFinished = action.payload
    },
    setRecordModalIsOpen: (state, action) => {
      state.recordModalIsOpen = action.payload
    },
    setConversationMemorizeSpeechType: (state, action) => {
      state.data.dialogData[action.payload.dialogIndex].speechType = action.payload.speechType
    },
    resetMemorizeDialog: (state, action) => {
      const dialog = state.userAnswers.find(obj => obj.dialogNumber === action.payload.dialogNumber)
      if (dialog) {
        state.userAnswers = [...state.userAnswers].map(item => {
          if (item.dialogNumber === action.payload.dialogNumber) {
            return {
              ...item,
              sentence: '',
              unlocked: false,
              checked: false,
              checkResult: null,
              usedHint: false
            }
          }
          return item
        })
      }
    },
    setSpeechDialogNumber: (state, action) => {
      state.speechDialogNumber = action.payload
    },
    renameArchiveResponse: (state, action) => {
      state.archiveResponse = {
        ...state.archiveResponse,
        fileName: action.payload
      }
    },
    setPlaylist: (state, action) => {
      state.playlist = action.payload
    },
    unlockPlayingDialogs: (state, action) => {
      // Get playing dialogs
      const playingDialogs = state.data.dialogData.filter((obj, i) => i <= action.payload)
      // If playingDialogs array is not empty
      if (playingDialogs.length > 0) {
        // Map on checked dialogs
        state.userAnswers = [...state.userAnswers]
          .filter(obj => obj.checked)
          .map(item => {
            // If user answer isn't unlocked
            if (!item.unlocked) {
              // If playing dialogs includes this user answer.. Unlock it!
              const dialog = playingDialogs.find(obj => obj.dialogNumber === item.dialogNumber)
              if (dialog) {
                return {
                  ...item,
                  unlocked: true
                }
              }
            }
            return item
          })
      }
    },
    setUserVoiceText: (state, action) => {
      state.recordModalIsOpen = false
      state.voiceStr = action.payload.text
    },
    setRolePlayFinalAudio: (state, action) => {
      state.finalAudio = {
        id: action.payload.id,
        blobUrl: action.payload.blobUrl,
        title: action.payload.title,
        name: action.payload.name,
        createdDate: action.payload.createdDate
      }
    },
    renameRolePlayFile: (state, action) => {
      state.finalAudio = {
        ...state.finalAudio,
        name: action.payload
      }
    },
    resetRolePlay: state => {
      state.rolePlayActiveDialog = null
      state.finalAudio = null
      state.archiveLoading = false
      state.archiveResponse = null
      state.rolePlayFinished = false
      state.rolePlayOnDialog = null
      state.recording = false
      state.voiceStr = ''
    },
    clearConversationStates: state => {
      state.loading = false
      state.isChecking = false
      state.data = {}
      state.page = 'intro'
      state.error = ''
      state.typeMethod = 'keyboard'
      state.memorizeCharacterSelected = false
      state.memorizeSelectedCharacters = []
      state.rolePlayCharacterSelected = false
      state.rolePlaySelectedCharacter = null
      state.playlist = []
      state.characters = []
      state.userAnswers = []
      state.speechDialogNumber = null
      state.rolePlayActiveDialog = null
      state.rolePlayShowText = false
      state.rolePlayOnDialog = null
      state.rolePlayFinished = false
      state.recording = false
      state.recordModalIsOpen = false
      state.spellPending = false
      state.voiceStr = ''
      state.archiveLoading = false
      state.archiveResponse = null
      state.finalAudio = null
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchConversation.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchConversation.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
    })
    builder.addCase(fetchConversation.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(checkConversationMemorize.pending, state => {
      state.isChecking = true
    })
    builder.addCase(checkConversationMemorize.fulfilled, (state, action) => {
      state.isChecking = false

      const matchCasesMap = action.payload.data.matchCasesMap

      if (isArray(action.payload.dialogNumber)) {
        let matchCaseIndex = 0
        state.userAnswers = [...state.userAnswers].map(obj => {
          if (action.payload.dialogNumber.includes(obj.dialogNumber)) {
            const matchCase = matchCasesMap[Object.keys(matchCasesMap)[matchCaseIndex]]
            matchCaseIndex++

            const isCorrect = matchCase.userAnswerStatus === AnswerStatusEnums.CT

            return {
              ...obj,
              checked: true,
              isCorrect,
              isCorrectWithMistake:
                isCorrect && matchCase.wordByWordResult?.some(obj => obj.match && obj.actionToCorrect === 1),
              checkResult: matchCase.wordByWordResult
            }
          } else {
            return obj
          }
        })
      } else {
        state.userAnswers = [...state.userAnswers].map(obj => {
          if (obj.dialogNumber === action.payload.dialogNumber) {
            const matchCase = matchCasesMap[Object.keys(matchCasesMap)[0]]

            for (let i = 0; i < matchCase.wordByWordResult.length; i++) {
              if (!matchCase.wordByWordResult[i].match) {
                break
              }
            }

            const isCorrect = matchCase.userAnswerStatus === AnswerStatusEnums.CORRECT
            const isSkipped = matchCase.userAnswerStatus === AnswerStatusEnums.SKIPPED

            playCheckSound(isCorrect, isSkipped)

            return {
              ...obj,
              checked: true,
              isCorrect,
              isCorrectWithMistake:
                isCorrect && matchCase.wordByWordResult?.some(obj => obj.actionToCorrect > 0 && obj.usrStr !== ''),
              checkResult: matchCase.wordByWordResult
            }
          } else {
            return obj
          }
        })
      }
    })
    builder.addCase(checkConversationMemorize.rejected, (state, action) => {
      state.isChecking = false
      state.error = action.error.message
    })
    builder.addCase(getConversationMemorizeHint.pending, state => {
      state.isChecking = true
    })
    builder.addCase(getConversationMemorizeHint.fulfilled, (state, action) => {
      state.isChecking = false

      state.userAnswers = [...state.userAnswers].map(obj => {
        if (obj.dialogNumber === action.payload.dialogNumber) {
          return {
            ...obj,
            sentence: action.payload.data
          }
        } else {
          return obj
        }
      })
    })
    builder.addCase(getConversationMemorizeHint.rejected, (state, action) => {
      state.isChecking = false
      state.error = action.error.message
    })
    builder.addCase(speechToText.pending, state => {
      state.spellPending = true
    })
    builder.addCase(speechToText.fulfilled, (state, action) => {
      state.spellPending = false
      state.recordModalIsOpen = false
    })
    builder.addCase(speechToText.rejected, (state, action) => {
      state.spellPending = false
      state.error = action.error.message
      state.recordModalIsOpen = false
    })
    builder.addCase(postArchiveRolePlay.pending, state => {
      state.archiveLoading = true
    })
    builder.addCase(postArchiveRolePlay.fulfilled, (state, action) => {
      state.archiveLoading = false
      state.archiveResponse = action.payload.data
    })
    builder.addCase(postArchiveRolePlay.rejected, (state, action) => {
      state.archiveLoading = false
      state.error = action.error.message
    })
    builder.addCase(destroyArchiveFile.fulfilled, (state, action) => {
      state.rolePlayActiveDialog = null
      state.rolePlayFinished = false
      state.archiveLoading = false
      state.archiveResponse = null
    })
    builder.addCase(destroyArchiveFile.rejected, (state, action) => {
      state.error = action.error.message
    })
  }
})

export const {
  setPage,
  setTypeMethod,
  setInputValue,
  setUserAnswers,
  toggleLock,
  setRolePlayActiveDialog,
  setRecordModalIsOpen,
  setRolePlayFinished,
  resetMemorizeDialog,
  setSpeechDialogNumber,
  setMemorizeCharacterSelected,
  setMemorizeSelectedCharacters,
  setRolePlayCharacterSelected,
  setRolePlaySelectedCharacter,
  setRolePlayShowText,
  setRolePlayOnDialog,
  renameArchiveResponse,
  setPlaylist,
  unlockPlayingDialogs,
  setUserVoiceText,
  setRolePlayFinalAudio,
  renameRolePlayFile,
  resetRolePlay,
  clearConversationStates,
  setConversationMemorizeSpeechType
} = conversationSlice.actions

export default conversationSlice.reducer
