import levelsMapper from '../../services/mapper/levelAndUnit/levelsMapper'
import { fetchLevels } from '../../services/cloudServices'

const levelsUseCase = async ({ oldLevels }) => {
  const response = await fetchLevels(null)

  return levelsMapper(response, oldLevels)
}

export default levelsUseCase
