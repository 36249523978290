import newspaperMapper from '../../services/mapper/newspapers/newspaperMapper'
import { fetchNewspapers } from '../../services/cloudServices'

const newspapersUseCase = async () => {
  const result = await fetchNewspapers(null)

  return result.map(video => {
    return newspaperMapper(video)
  })
}

export default newspapersUseCase
