import unitsMapper from '../../services/mapper/levelAndUnit/unitsMapper'
import { fetchUnits } from '../../services/cloudServices'

const unitsUseCase = async ({ level }) => {
  const response = await fetchUnits({ level: level.level })

  return {
    level: level.level,
    units: unitsMapper(response, level)
  }
}

export default unitsUseCase
