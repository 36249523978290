import todayStudyFirstPlan from 'services/offlineServicesData/todayStudy/todayStudyFirstPlan.json'
import todayStudyMapper from 'services/mapper/todayStudy/todayStudyMapper'
import { fetchHomeTodayStudy } from 'services/cloudServices'

const homeTodayStudyUseCase = async () => {
  const result = await fetchHomeTodayStudy(null)

  return todayStudyMapper(result)
}

export default homeTodayStudyUseCase
