import { subscriptionGooglePlan } from '../../services/cloudServices'

const subscriptionGoogleUseCase = async ({ psp, planId, asRenewal, purchaseType, purchaseToken }) => {
  const result = await subscriptionGooglePlan(
    { psp, planId, asRenewal, purchaseType, purchaseToken }
  )

  return result
}

export default subscriptionGoogleUseCase
