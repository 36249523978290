import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { updateCourseIntensityActionService, updateStudyPreferences } from '../../services/cloudServices'

const initialState = {
  loading: false,
  submitResponse: null,
  selectedCourse: null
}

export const submitStudyPreferences = createAsyncThunk(
  'courseIntensity/submitStudyPreferences',
  ({ learningType, spacedRepetition, courseIntensity }, { getState }) => {
    const { appUser } = getState()

    return updateStudyPreferences(
      {
        learningType: learningType ?? appUser?.appUserData?.setting?.studyPreferences?.learningType,
        spacedRepetition: spacedRepetition ?? appUser?.appUserData?.setting?.studyPreferences?.spacedRepetition,
        courseIntensity: courseIntensity ?? appUser?.appUserData?.setting?.studyPreferences?.courseIntensity
      }
    )
  }
)

export const updateCourseIntensityAction = createAsyncThunk(
  'courseIntensity/updateCourseIntensityAction',
  ({ courseIntensity }) => {
    return updateCourseIntensityActionService({ courseIntensity })
  }
)

const courseIntensitySlice = createSlice({
  name: 'courseIntensity',
  initialState,
  reducers: {
    onSelectCourse: (state, action) => {
      state.selectedCourse = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(submitStudyPreferences.pending, state => {
      state.loading = true
    })
    builder.addCase(submitStudyPreferences.fulfilled, (state, action) => {
      state.loading = false
      state.submitResponse = action.payload.data
    })
    builder.addCase(submitStudyPreferences.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const { onSelectCourse } = courseIntensitySlice.actions

export default courseIntensitySlice.reducer
