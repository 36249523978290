import phraseTableMapper from '../../services/mapper/vocabulary/phraseTableMapper'
import { fetchPhrases } from '../../services/cloudServices'

const getPhraseTableTypeUseCase = async ({ unitId, searchTerm, sortBy, sortType, paged, page, size, baseData }) => {
  const result = await fetchPhrases({ unitId, searchTerm, sortBy, sortType, paged, page, size })

  return {
    ...result,
    content: phraseTableMapper(result?.content, baseData)
  }
}

export default getPhraseTableTypeUseCase
