import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getRoboTeacherUseCase } from '../../useCase/roboTeacher/getRoboTeacherUseCase'
import { ENGLISH, NATIVE } from '../../enums/roboTeacher/dataStatusEnums'
import { ContentTypesStructureEnums } from '../../enums/structureEnums/templateType'
import { TextAlignmentEnums } from '../../enums/globalEnums/globalEnums'

const initialState = {
  loading: false,
  error: '',
  roboTeacherData: null,
  disableWordColor: false,
  currentData: null,
  robotMovement: {
    position: TextAlignmentEnums.CENTER,
    action: null,
    previousPosition: TextAlignmentEnums.CENTER,
    walkingAction: null,
    previousAction: null
  },
  currentDataStatus: ENGLISH,
  disableControls: false,
  events: null,
  scrolling: false,
  lastSeekTime: null,
  lastDataStatus: null,
  lastDataUnit: null,
  lastScrollY: null
}

export const getRoboTeacherData = createAsyncThunk('roboTeacher/fetchRoboTeacher', ({ unitId }) => {
  return getRoboTeacherUseCase({ unitId })
})

const roboTeacherSlice = createSlice({
  name: 'roboTeacher',
  initialState,
  reducers: {
    updateRoboTeacherCurrentData: (state, action) => {
      if (state.currentDataStatus === ENGLISH) {
        state.currentData = state.roboTeacherData?.nativeData ?? null
        state.currentDataStatus = NATIVE
      } else if (state.currentDataStatus === NATIVE) {
        state.currentData = state.roboTeacherData?.english ?? null
        state.currentDataStatus = ENGLISH
      }
    },
    updateRoboTeacherCurrentDataStatus: (state, action) => {
      if (action.payload === NATIVE) {
        state.currentDataStatus = NATIVE
      } else if (action.payload === ENGLISH) {
        state.currentDataStatus = ENGLISH
      }
    },
    setRobotMovement: (state, action) => {
      if (action.payload?.action) {
        state.robotMovement.action = action.payload.action
        state.robotMovement.currentAction = action.payload.action
      }
      if (action.payload?.position) {
        state.robotMovement.position = action.payload.position
      }
    },
    setRobotPreviousAction: (state, action) => {
      state.robotMovement.previousAction = action.payload
    },
    setRobotCurrentAction: (state, action) => {
      state.robotMovement.currentAction = action.payload
    },
    setRobotPreviousPosition: (state, action) => {
      state.robotMovement.previousPosition = action.payload
    },
    setRobotWalkingAction: (state, action) => {
      state.robotMovement.walkingAction = action.payload
    },
    setRoboTeacherMoreSampleData: (state, action) => {
      if (state.roboTeacherData?.nativeData?.templates?.length > 0) {
        state.roboTeacherData.nativeData.templates = [...state.roboTeacherData.nativeData.templates].map(template => {
          if (
            template.type === ContentTypesStructureEnums.BUTTON &&
            template.config?.moreSectionId === action.payload.moreSectionId
          ) {
            return {
              ...template,
              structuresData: action.payload.data
            }
          }
          return template
        })
      }

      if (state.roboTeacherData?.english?.templates?.length > 0) {
        state.roboTeacherData.english.templates = [...state.roboTeacherData.english.templates].map(template => {
          if (
            template.type === ContentTypesStructureEnums.BUTTON &&
            template.config?.moreSectionId === action.payload.moreSectionId
          ) {
            return {
              ...template,
              structuresData: action.payload.data
            }
          }
          return template
        })
      }

      if (state.currentDataStatus === NATIVE) {
        state.currentData = state.roboTeacherData?.nativeData
      } else {
        state.currentData = action.payload.english
      }
    },
    setScrolling: (state, action) => {
      state.scrolling = action.payload
    },
    setLastSeekTime: (state, action) => {
      state.lastSeekTime = action.payload
    },
    setLastDataStatus: (state, action) => {
      state.lastDataStatus = action.payload
    },
    setLastDataUnit: (state, action) => {
      state.lastDataUnit = action.payload
    },
    setLastScrollY: (state, action) => {
      state.lastScrollY = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getRoboTeacherData.pending, state => {
      state.loading = true
    })
    builder.addCase(getRoboTeacherData.fulfilled, (state, action) => {
      state.loading = false
      state.roboTeacherData = action.payload

      if (state.currentDataStatus === NATIVE && state.roboTeacherData?.nativeData?.voice) {
        state.currentData = state.roboTeacherData?.nativeData
      } else {
        state.currentDataStatus = ENGLISH
        state.currentData = state.roboTeacherData?.english
      }
      state.events = action.payload
    })
    builder.addCase(getRoboTeacherData.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const {
  updateRoboTeacherCurrentData,
  updateRoboTeacherCurrentDataStatus,
  setRobotMovement,
  setRobotPreviousAction,
  setRobotCurrentAction,
  setRobotPreviousPosition,
  setRoboTeacherMoreSampleData,
  setScrolling,
  setLastSeekTime,
  setLastDataStatus,
  setLastDataUnit,
  setLastScrollY
} = roboTeacherSlice.actions

export default roboTeacherSlice.reducer
