import reviewMistakeDragAndDropConversationMapper from '../../services/mapper/reviewMistake/reviewMistakeDragAndDropConversationMapper'
import exerciseTypeEnums from '../../enums/exerciseEnums/exerciseEnums'
import reviewMistakeDragAndDropMapper from '../../services/mapper/reviewMistake/reviewMistakeDragAndDropMapper'
import { fetchSectionExercise } from '../../services/cloudServices'

const getReviewMistakeExerciseUseCase = async ({
  unit,
  section,
  sectionNumber,
  groupType,
  filterByIds,
  filterByUserAnswerData
}) => {
  const response = await fetchSectionExercise({ unit, section, sectionNumber, groupType })

  if (response?.type === exerciseTypeEnums.dragAndDropConversation) {
    return reviewMistakeDragAndDropConversationMapper(response, filterByIds)
  } else if (response?.type === exerciseTypeEnums.dragAndDrop) {
    return reviewMistakeDragAndDropMapper(response, filterByUserAnswerData)
  }
}

export default getReviewMistakeExerciseUseCase
