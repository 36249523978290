import boxMapper from '../../services/mapper/memoryBox/boxMapper'
import { ALL, VOCABULARY } from '../../enums/globalEnums/globalEnums'
import { fetchYourSelectionBoxes } from '../../services/cloudServices'

const getYourSelectionBoxesUseCase = async ({ page, categoryId }) => {
  const boxes = await fetchYourSelectionBoxes(
    {
      categoryId,
      isVocab: page === ALL ? '' : page === VOCABULARY
    }
  )

  return boxes.map(item => {
    return boxMapper(item)
  })
}

export default getYourSelectionBoxesUseCase
