import userUnitStatusMapper from '../../services/mapper/statistics/userUnitStatusMapper'
import { fetchUserUnitStatusUseCase } from '../../services/cloudServices'

const userUnitStatusUseCase = async ({ unitId, numberOfRepetitions }) => {
  const response = await fetchUserUnitStatusUseCase({ unitId })

  return userUnitStatusMapper(response, numberOfRepetitions)
}

export default userUnitStatusUseCase
