import { subscriptionStripePlan } from '../../services/cloudServices'

const subscriptionStripeUseCase = async ({ asRenewal, discountCode, planId, psp, paymentMethodId, purchaseType }) => {
  const result = await subscriptionStripePlan(
    { asRenewal, discountCode, planId, psp, paymentMethodId, purchaseType }
  )

  return result
}

export default subscriptionStripeUseCase
