import filesAndFoldersMapper from '../../services/mapper/notepad/filesAndFoldersMapper'
import { notepadMoveItem } from '../../services/cloudServices'

const moveItemsUseCase = async ({ currentFolderId, destinationFolderId, data }) => {
  data = data.map(it => {
    return {
      id: it.id,
      userId: it.id,
      title: it.title,
      color: it.color,
      parentFolderId: it.parentFolderId ?? '',
      fileType: it.fileType,
      createdDate: it.date
    }
  })

  const result = await notepadMoveItem({ currentFolderId, destinationFolderId, data })

  return filesAndFoldersMapper(result.currentFolderContent)
}

export default moveItemsUseCase
