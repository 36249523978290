import filesAndFoldersMapper from '../../services/mapper/notepad/filesAndFoldersMapper'
import { notepadAddFile } from '../../services/cloudServices'

const addFileUseCase = async data => {
  const result = await notepadAddFile(data)

  return result?.folderAndFiles?.map(item => {
    return filesAndFoldersMapper(item)
  })
}

export default addFileUseCase
