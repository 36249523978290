import boxMapper from '../../services/mapper/memoryBox/boxMapper'
import { fetchBoxes } from '../../services/cloudServices'

const getBoxesUseCase = async ({ categoryId }) => {
  const boxes = await fetchBoxes({ categoryId })

  return boxes.map(item => {
    return boxMapper(item)
  })
}

export default getBoxesUseCase
