import sectionsMapper from '../../services/mapper/levelAndUnit/sectionsMapper'
import { fetchSections } from '../../services/cloudServices'

const sectionsUseCase = async ({ level, unitId, studyType }) => {
  const response = await fetchSections({ unitId, studyType })

  return {
    level,
    unitId,
    sections: sectionsMapper(response)
  }
}

export default sectionsUseCase
