import filesAndFoldersMapper from '../../services/mapper/notepad/filesAndFoldersMapper'
import { notepadEditFile } from '../../services/cloudServices'

const editFileUseCase = async data => {
  const result = await notepadEditFile(
    {
      id: data.id,
      parentFolderId: data.parentFolderId,
      body: {
        title: data.title,
        color: data.color,
        text: data.text,
        textConfig: data.textConfig
      }
    }
  )

  return filesAndFoldersMapper(result)
}

export default editFileUseCase
