import plansMapper from 'services/mapper/payment/plansMapper'
import { fetchChooseCourse } from 'services/cloudServices'

const getPlansUseCase = async ({ asRenewal, purchaseType }) => {
  const result = await fetchChooseCourse({ asRenewal })

  return plansMapper(result, purchaseType)
}

export default getPlansUseCase
