import memoryTypeTestMapper from '../../services/mapper/memoryTypeTest/memoryTypeTestMapper'
import { fetchMemoryTypeTest } from '../../services/cloudServices'

const memoryTypeTestUseCase = async data => {
  const result = await fetchMemoryTypeTest(data)

  return memoryTypeTestMapper(result)
}

export default memoryTypeTestUseCase
