import todayStudyPlanMapper from '../../services/mapper/todayStudy/todayStudyPlanMapper'
import { fetchTodayStudyPlan } from '../../services/cloudServices'

const todayStudyPlanUseCase = async ({ baseData, pageIndex }) => {
  const result = await fetchTodayStudyPlan({ pageIndex })

  return todayStudyPlanMapper(result, baseData)
}

export default todayStudyPlanUseCase
