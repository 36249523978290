import plansMapper from 'services/mapper/payment/plansMapper'
import { fetchDiscounted } from 'services/cloudServices'
import farsiToEnglishNum from 'util/farsiToEnglishNum/farsiToEnglishNum'

const applyDiscountCodeUseCase = async ({ discountCode, asRenewal, purchaseType }) => {
  const result = await fetchDiscounted({
    discountCode: farsiToEnglishNum(discountCode),
    asRenewal
  })

  return plansMapper(result, purchaseType)
}

export default applyDiscountCodeUseCase
