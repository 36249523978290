import { setPasswordActionEnums } from 'enums/inquiryEnums/inquiryEnums'
import { postInquiryResetPassword } from 'services/cloudServices'

const inquiryResetPasswordUseCase = async data => {
  const response = await postInquiryResetPassword({
    authData: {
      // platform: data.authData?.platform,
      username: data.authData?.username,
      password: data.authData?.password,
      rePassword: data.authData?.rePassword,
      code: data.authData?.code,
      state: data.authData?.state
    },
    captchaToken: data?.captchaToken
  })

  return { ...response, action: response.action ?? setPasswordActionEnums.DONE }
}

export default inquiryResetPasswordUseCase
