import filesAndFoldersMapper from '../../services/mapper/notepad/filesAndFoldersMapper'
import { notepadDeleteItems } from '../../services/cloudServices'

const deleteItemsUseCase = async ({ parentFolderId, items }) => {
  items = items.map(it => {
    return {
      id: it.id,
      fileType: it.fileType
    }
  })

  const result = await notepadDeleteItems({ parentFolderId, items })

  return filesAndFoldersMapper(result)
}

export default deleteItemsUseCase
