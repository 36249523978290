import { subscriptionPayPalPlan } from '../../services/cloudServices'

const subscriptionPayPalUseCase = async ({ asRenewal, discountCode, planId, psp, purchaseType }) => {
  const result = await subscriptionPayPalPlan(
    { asRenewal, discountCode, planId, psp, purchaseType }
  )

  return result
}

export default subscriptionPayPalUseCase
