import { useEffect } from 'react'
import { hideLoader, showLoader } from '../../helper'
import FadeMotion from '../../layouts/Motion/FadeMotion'

const LoadingPage = () => {
  useEffect(() => {
    showLoader()
    return () => {
      hideLoader()
    }
  }, [])

  return <FadeMotion className='minh-100vh w-100' />
}

export default LoadingPage
