import faqMapper from '../../services/mapper/faq/faqMapper'
import { fetchFaqList } from '../../services/cloudServices'

const getFaqUseCase = async ({ nativeLanguage }) => {
  const result = await fetchFaqList({ nativeLanguage })

  return faqMapper(result)
}

export default getFaqUseCase
