import staticContentMapper from '../../services/mapper/staticContent/staticContentMapper'
import { fetchStaticContentData } from '../../services/cloudServices'

const staticContentUseCase = async ({ language, type }) => {
  const response = await fetchStaticContentData({ language, type })

  return staticContentMapper(response)
}

export default staticContentUseCase
