import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchYourGamesRecords } from '../../services/cloudServices'

const initialState = {
  loading: false,
  data: null,
  error: ''
}

export const getAllGameRecords = createAsyncThunk('yourGamesRecord/getAllGameRecords', () => {
  return fetchYourGamesRecords({})
})

const yourGamesRecordSlice = createSlice({
  name: 'yourGamesRecord',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAllGameRecords.pending, state => {
      state.loading = true
    })
    builder.addCase(getAllGameRecords.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload.data
    })
    builder.addCase(getAllGameRecords.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const {} = yourGamesRecordSlice.actions

export default yourGamesRecordSlice.reducer
