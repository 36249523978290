import React, { useContext, useEffect, useMemo, useState } from 'react'
import componentStyle from './ExerciseAIAssistantModal.module.scss'
import colorsContext from '../../contexts/ColorsContext'
import Modal from '../Modal'
import { isMobile } from 'react-device-detect'
import BottomSheet from '../BottomSheet/BottomSheet'
import ThumbUpSvg from '../../icons/ThumbUpSvg'
import classNames from 'classnames'
import ThumbDownSvg from '../../icons/ThumbDownSvg'
import AISvg from '../../icons/AISvg'
import AICreditBadge from '../AICreditBadge/AICreditBadge'
import { useDispatch, useSelector } from 'react-redux'
import { getExerciseAssistant } from '../../redux/features/aiChatSlice'
import HtmlTextWrapper from '../HtmlTextWrapper/HtmlTextWrapper'
import { Spinner } from 'react-bootstrap'
import ThumbUpFillSvg from '../../icons/ThumbUpFillSvg'
import ThumbDownFillSvg from '../../icons/ThumbDownFillSvg'
import ProvideAIChatFeedbackModal from '../ProvideAIChatFeedbackModal/ProvideAIChatFeedbackModal'
import { setAiTokens } from '../../redux/features/appUserSlice'
import Markdown from 'markdown-to-jsx'

import { renderMarkdownWithDirection } from 'helper'

const ExerciseAIAssistantModal = ({ exerciseItemId, closeModal, correctAnswer, userAnswer }) => {
  const { colors } = useContext(colorsContext)
  const dispatch = useDispatch()
  const { assistance, loading } = useSelector(state => state.aiChat)
  const [feedback, setFeedback] = useState({
    isOpen: false,
    dialogId: null,
    isLiked: false
  })

  const assistanceData = useMemo(() => {
    return assistance.find(it => it.exerciseItemId === exerciseItemId)
  }, [JSON.stringify(assistance), exerciseItemId])

  useEffect(() => {
    if (!assistanceData || userAnswer !== assistanceData.userAnswer) {
      dispatch(getExerciseAssistant({ exerciseItemId, correctAnswer, userAnswer })).then(res => {
        dispatch(setAiTokens(res.payload.totalTokens))
      })
    }
  }, [exerciseItemId, userAnswer])

  const onClickFeedback = isLiked => {
    setFeedback({
      isOpen: true,
      dialogId: assistanceData?.id,
      isLiked
    })
  }

  const renderModalContent = () => {
    return (
      <>
        <div>
          <div className='d-flex justify-content-end mb-2'>
            <AICreditBadge showTitle titleColor={colors['C589']} />
          </div>
          <div style={{ color: colors['C589'] }} className={classNames(componentStyle.title, 'res:fs-lg')}>
            <span className={componentStyle.icon} style={{ background: colors['C160'] }}>
              <AISvg color={colors['C233']} />
            </span>
            Exercises AI Assistant
          </div>
          <div className={classNames(componentStyle.contentCard, 'res:fs-md')} style={{ borderColor: colors['C160'] }}>
            {loading ? (
              <div className='my-2'>
                <Spinner />
              </div>
            ) : (
              <HtmlTextWrapper isMarkDown={true} data={assistanceData?.content} textColor={colors['C589']} />
              // <div>
              //   <Markdown>{splitingText(assistanceData?.content, true)}</Markdown>
              // </div>
            )}
            <div className={componentStyle.feedbackIcons}>
              <button disabled={assistanceData?.feedback?.liked === true} onClick={() => onClickFeedback(true)}>
                {assistanceData?.feedback && assistanceData?.feedback?.liked ? (
                  <ThumbUpFillSvg color={colors['C589']} />
                ) : (
                  <ThumbUpSvg color={colors['C589']} />
                )}
              </button>
              <button disabled={assistanceData?.feedback?.liked === false} onClick={() => onClickFeedback(false)}>
                {assistanceData?.feedback && !assistanceData?.feedback?.liked ? (
                  <ThumbDownFillSvg color={colors['C589']} />
                ) : (
                  <ThumbDownSvg color={colors['C589']} />
                )}
              </button>
            </div>
          </div>
        </div>
        {feedback.isOpen && (
          <ProvideAIChatFeedbackModal
            promptId={assistanceData?.id}
            isLiked={feedback.isLiked}
            dialogId={feedback.dialogId}
            isExerciseAssistance
            closeModal={() =>
              setFeedback({
                isOpen: false,
                dialogId: null,
                isLiked: false
              })
            }
          />
        )}
      </>
    )
  }

  if (isMobile) {
    return (
      <BottomSheet close={closeModal} hasSpace>
        {() => renderModalContent()}
      </BottomSheet>
    )
  } else {
    return (
      <Modal closeModal={closeModal} isCenter>
        <div className='modal-type-2 scrollable py-4 px-3' style={{ background: colors['C73'], borderRadius: 10 }}>
          {renderModalContent()}
        </div>
      </Modal>
    )
  }
}

export default ExerciseAIAssistantModal
