import todayStudyMapper from '../../services/mapper/todayStudy/todayStudyMapper'
import { fetchTodayStudy } from '../../services/cloudServices'

const todayStudyUseCase = async () => {
  const result = await fetchTodayStudy(null)

  return {
    todayStudy: todayStudyMapper(result?.todayStudy),
    quickReview: result?.quickReview
  }
}

export default todayStudyUseCase
