import classNames from 'classnames'
import HtmlTextWrapperStyle from './HtmlTextWrapper.module.scss'
import useQueryParams from 'hooks/useQueryParams'
import { useParams } from 'react-router-dom'

const WordLink = ({ highlighted, content, hrefValue }) => {
  const { addQueryParams } = useQueryParams()
  const { unit } = useParams()

  const onClickAnchor = hrefValue => {
    addQueryParams({
      openPhraseOptions: hrefValue,
      phraseType: 'dictionary',
      phraseUnit: unit
    })
  }

  return (
    <>
      <span
        className={classNames(HtmlTextWrapperStyle.word, 'tw-cursor-pointer', {
          [HtmlTextWrapperStyle.highlighted]: highlighted
        })}
        onClick={() => onClickAnchor(hrefValue)}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </>
  )
}

export default WordLink
