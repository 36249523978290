import filesAndFoldersMapper from '../../services/mapper/notepad/filesAndFoldersMapper'
import { fetchAllNotepadFileAndFolder } from '../../services/cloudServices'

const getAllNotepadFileAndFolderUseCase = async ({ folderId }) => {
  const result = await fetchAllNotepadFileAndFolder({ folderId })

  return filesAndFoldersMapper(result)
}

export default getAllNotepadFileAndFolderUseCase
