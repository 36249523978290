import fetchDrillMapper from '../../services/mapper/sections/fetchDrillMapper'
import { fetchSection } from '../../services/cloudServices'

const fetchDrillUseCase = async ({ unit, nativeLanguage, studyStatus }) => {
  const result = await fetchSection(
    {
      unit: unit,
      section: 'DRILLING'
    }
  )

  return {
    data: fetchDrillMapper(result?.data),
    unit,
    nativeLanguage,
    studyStatus
  }
}

export default fetchDrillUseCase
