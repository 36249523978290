import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseAsyncThunk } from '../baseAsyncThunk'
import { fetchRoboChatDialog, spell } from '../../services/cloudServices'

const initialState = {
  loading: false,
  pendingRoboChat: false,
  dialogs: [],
  dialogChoices: [],
  chatFinished: false,
  typeMethod: '',
  inputText: '',
  displayInputText: null,
  closingDisplayInputText: false,
  answersChecked: false,
  recordModalIsOpen: false,
  spellPending: false,
  playlist: [],
  error: ''
}

export const fetchRoboChat = baseAsyncThunk('roboChat/fetchRoboChat', ({ unitId, dialogId }) => {
  return fetchRoboChatDialog({ unitId, dialogId })
})

export const speechToText = createAsyncThunk('roboChat/speechToText', ({ formData }) => {
  return spell({ formData })
})

const roboChatSlice = createSlice({
  name: 'roboChat',
  initialState,
  reducers: {
    setTypeMethod: (state, action) => {
      state.typeMethod = action.payload
    },
    setUserDialog: (state, action) => {
      state.dialogs = [...state.dialogs, { ...action.payload.dialog, isRobot: false }]
    },
    setChatFinished: (state, action) => {
      state.chatFinished = action.payload
    },
    resetChat: state => {
      state.dialogs = []
      state.chatFinished = false
      state.inputText = ''
      state.error = ''
    },
    setInputText: (state, action) => {
      state.inputText = action.payload
    },
    setDisplayInputText: (state, action) => {
      state.displayInputText = action.payload
    },
    setRecordModalIsOpen: (state, action) => {
      state.recordModalIsOpen = action.payload
    },
    setPlaylist: (state, action) => {
      state.playlist = action.payload
    },
    setUserVoiceText: (state, action) => {
      state.spellPending = false
      state.inputText = action.payload.text
      state.typeMethod = ''
      state.recordModalIsOpen = false
    },
    setPendingRoboChat: (state, action) => {
      state.pendingRoboChat = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchRoboChat.pending, state => {
      state.pendingRoboChat = true
      state.loading = true
    })
    builder.addCase(fetchRoboChat.fulfilled, (state, action) => {
      state.loading = false

      const robotDialogs = [...action.payload.data.robotChatQuestion.dialogs].map(dialog => {
        return {
          isRobot: true,
          ...dialog
        }
      })

      state.dialogs = [...state.dialogs, ...robotDialogs]

      if (action.payload.data.robotChatAnswer?.dialogs?.length > 0) {
        state.dialogChoices = [...action.payload.data.robotChatAnswer.dialogs]
      } else {
        state.dialogChoices = []
        state.chatFinished = true
      }
    })
    builder.addCase(fetchRoboChat.rejected, (state, action) => {
      state.pendingRoboChat = false
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const {
  setTypeMethod,
  setChatFinished,
  resetChat,
  setInputText,
  setDisplayInputText,
  setRecordModalIsOpen,
  setPlaylist,
  setUserVoiceText,
  setUserDialog,
  setPendingRoboChat
} = roboChatSlice.actions

export default roboChatSlice.reducer
