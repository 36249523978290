export enum NativeLanguageEnum {
  persian = 'PERSIAN',
  arabic = 'ARABIC',
  spanish = 'SPANISH',
  french = 'FRENCH',
  russian = 'RUSSIAN',
  turkish = 'TURKISH',
  italian = 'ITALIAN',
  german = 'GERMAN'
}

export enum SystemLanguageEnum {
  english = 'ENGLISH',
  persian = 'PERSIAN',
  arabic = 'ARABIC',
  spanish = 'SPANISH',
  french = 'FRENCH',
  russian = 'RUSSIAN',
  turkish = 'TURKISH',
  italian = 'ITALIAN',
  german = 'GERMAN'
}
