import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchNumbersSection } from '../../services/cloudServices'


const initialState = {
  loading: false,
  data: null,
  error: ''
}

export const getNumbersSection = createAsyncThunk('numberSection/fetchNumbersSection', () => {
  return fetchNumbersSection( null )
})

const numberSectionSlice = createSlice({
  name: 'numberSectionSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getNumbersSection.pending, state => {
      state.loading = true
    })
    builder.addCase(getNumbersSection.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload.data
    })
    builder.addCase(getNumbersSection.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

// export const {} = numberSectionSlice.actions

export default numberSectionSlice.reducer
