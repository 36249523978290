export default [
  {
    translationKey: 'GATEWAY_CAPTCHA_VALIDATION_FAILED',
    translationValue: 'Error en la validación del Captcha.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_CREATE_FAILED',
    translationValue: 'No se pudo crear el usuario.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_NOT_FOUND',
    translationValue: 'El nombre de usuario no existe.'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_INCORRECT',
    translationValue: '¡La contraseña es incorrecta!'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_EMPTY',
    translationValue: 'El campo de contraseña no puede estar vacío.'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_LENGTH',
    translationValue: 'La contraseña debe tener al menos tres caracteres.'
  },
  {
    translationKey: 'USER_MANAGEMENT_REPASSWORD_EMPTY',
    translationValue: 'El campo de confirmación de contraseña no puede estar vacío.'
  },
  {
    translationKey: 'USER_MANAGEMENT_REPASSWORD_NOT_MATCH',
    translationValue: 'La contraseña y su confirmación deben coincidir.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USERNAME_EMPTY',
    translationValue: 'El campo de nombre de usuario no puede estar vacío.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_INVALID',
    translationValue: 'El usuario no es válido.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_EXPIRED',
    translationValue: 'El código de confirmación ha expirado.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_INVALID',
    translationValue: 'El código de confirmación no es válido.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_EMPTY',
    translationValue: 'Por favor, ingrese el código de confirmación.'
  },
  {
    translationKey: 'USER_MANAGEMENT_OTP_RETRY',
    translationValue: 'Por favor, espere dos minutos.'
  },
  {
    translationKey: 'PAYMENT_IP_INVALID',
    translationValue: 'Su dirección IP no es válida.'
  },
  {
    translationKey: 'PAYMENT_REGION_INVALID',
    translationValue: 'El código de descuento no es válido en su región.'
  },
  {
    translationKey: 'PAYMENT_CODE_EXPIRED',
    translationValue: 'El código de descuento ha expirado.'
  },
  {
    translationKey: 'PAYMENT_CODE_UNAVAILABLE',
    translationValue: 'El código de descuento ya no está disponible.'
  },
  {
    translationKey: 'PAYMENT_CODE_USED',
    translationValue: 'Este código de descuento ya ha sido utilizado.'
  },
  {
    translationKey: 'CORE_AI_TOKEN_AMOUNT',
    translationValue: 'No tienes suficientes tokens.'
  },
  {
    translationKey: 'CORE_AI_ROLE_DESCRIPTION_INVALID',
    translationValue: 'No hay ninguna descripción de rol con este ID.'
  },
  {
    translationKey: 'CORE_AI_PROMPT_ID_INVALID',
    translationValue: 'No hay ningún prompt con este ID.'
  },
  {
    translationKey: 'CORE_AI_FEEDBACK_NOT_FOUND',
    translationValue: 'Comentario no encontrado.'
  },
  {
    translationKey: 'CORE_FILE_NOT_ENOUGH_SPACE',
    translationValue: 'No hay suficiente espacio en el almacenamiento.'
  },
  {
    translationKey: 'CORE_FAVORITE_CATEGORY_EXISTS',
    translationValue: '¡Ya existe una categoría con este nombre!'
  },
  {
    translationKey: 'CORE_FAVORITE_CATEGORY_NOT_FOUND',
    translationValue: '¡Categoría no encontrada!'
  },
  {
    translationKey: 'CORE_FAVORITE_ITEM_EXISTS',
    translationValue: 'Este elemento ya existe en la categoría seleccionada.'
  },
  {
    translationKey: 'CORE_MEMORY_BOX_CARD_EXISTS',
    translationValue: 'Esta tarjeta ya existe.'
  },
  {
    translationKey: 'CORE_MEMORY_BOX_CATEGORY_EXISTS',
    translationValue: 'Ya existe una categoría de tarjetas con este nombre.'
  },
  {
    translationKey: 'CORE_MEMORY_NOTEPAD_TITLE_EXISTS',
    translationValue: '¡El título ya existe! Elija otro título.'
  },
  {
    translationKey: 'CORE_DICTIONARY_WORD_NOT_FOUND',
    translationValue: 'La palabra no se ha encontrado en nuestra biblioteca.'
  },
  {
    translationKey: 'PAYMENT_CODE_INVALID',
    translationValue: 'El código de descuento es incorrecto. Verifíquelo e inténtelo de nuevo.'
  },
  {
    translationKey: 'OOPS_SOMETHING_WENT_WRONG',
    translationValue: '¡Ups! Algo salió mal.'
  },
  {
    translationKey: 'CORE_TURN_OFF_LEVEL_INVALID',
    translationValue: 'No puedes activar niveles anteriores.'
  },
  {
    translationKey: 'CORE_TURN_OFF_UNIT_INVALID',
    translationValue: 'No puedes desactivar unidades anteriores.'
  },
  {
    translationKey: 'CORE_TURN_ON_UNIT_INVALID',
    translationValue: 'No puedes activar unidades anteriores.'
  }
]
