import flashCardsMapper from '../../services/mapper/memoryBox/flashCardsMapper'
import { ASC, HISTORY } from '../../enums/globalEnums/globalEnums'
import { fetchAllHistoryMemoryBoxFlashCardsByIsVocab } from '../../services/cloudServices'

const getAllHistoryFlashCardByIsVocabUseCase = async ({ categoryId, searchTerm, sortBy, sortKey, isVocab }) => {
  const result = await fetchAllHistoryMemoryBoxFlashCardsByIsVocab(
    {
      boxType: HISTORY,
      categoryId: categoryId,
      frontText: searchTerm,
      sortAsc: sortBy === ASC,
      sortBy: sortKey,
      isVocab: isVocab
    }
  )

  return flashCardsMapper(result)
}

export default getAllHistoryFlashCardByIsVocabUseCase
