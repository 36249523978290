import tedVideoMapper from '../../services/mapper/ted/tedVideoMapper'
import { fetchTedVideos } from '../../services/cloudServices'

const tedVideoUseCase = async () => {
  const result = await fetchTedVideos(null)

  return result.map(video => {
    return tedVideoMapper(video)
  })
}

export default tedVideoUseCase
