import tableOfContentMapper from '../../services/mapper/tableOfContent/tableOfContentMapper'
import { fetchTableOfContent } from '../../services/cloudServices'

const tableOfContentUseCase = async () => {
  const result = await fetchTableOfContent(null)

  return tableOfContentMapper(result)
}

export default tableOfContentUseCase
