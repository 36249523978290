import nativeLanguageMapper from '../../services/mapper/nativeLanguage/nativeLanguageMapper'
import { fetchNativeLanguages } from '../../services/cloudServices'

const nativeLanguageUseCase = async () => {
  const result = await fetchNativeLanguages(null)

  return nativeLanguageMapper(result)
}

export default nativeLanguageUseCase
