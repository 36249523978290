import { fetchFavoriteCategories, fetchVocabularies } from '../../services/cloudServices'
import favoritesCategoryMapper from '../../services/mapper/favorite/favoritesCategoryMapper'

const getFavoritesCategoryUseCase = async ({ type }) => {
  const result = await fetchFavoriteCategories({ type })

  return favoritesCategoryMapper(result, type)
}

export default getFavoritesCategoryUseCase
