import allUnitGameStatisticMapper from '../../services/mapper/games/allUnitGameStatisticMapper'
import { fetchGameStatisticsAllUnits } from '../../services/cloudServices'

const gameUseCase = async ({ gameType }) => {
  try {
    const result = await fetchGameStatisticsAllUnits({ gameType })
    return allUnitGameStatisticMapper(result)
  } catch (error) {
    console.error('Error in gameUseCase:', error)
    return null
  }
}

export default gameUseCase
