import { postMatchingExactHint } from '../../services/cloudServices'

const matchingExactHintUseCase = async ({
  matchType,
  inputType,
  sectionType,
  toolsCheck,
  unitId,
  trackerNumber,
  matchCases,
  isVoiceRecognition,
  quizIndex,
  dialogNumber
}) => {
  const res = await postMatchingExactHint(
    {
      matchType,
      sectionType,
      toolsCheck,
      unitId,
      trackerNumber,
      matchCases,
      isVoiceRecognition,
      inputType
    }
  )

  const result = {
    data: res,
    quizIndex: quizIndex ?? null,
    dialogNumber: dialogNumber ?? null
  }

  return result
}

export default matchingExactHintUseCase
