import { addMemoryBoxFlashCard } from '../../services/cloudServices'

const addFlashCardInLessonUseCase = async ({ isVocab, text, unitId, trackerNumber }) => {
  const bodyFormData = new FormData()
  bodyFormData.append('isVocab', isVocab)
  bodyFormData.append('unitId', unitId)
  bodyFormData.append('front.text', text)
  bodyFormData.append('front.trackerNumber', trackerNumber)

  const result = addMemoryBoxFlashCard({ bodyFormData })
  return result
}

export default addFlashCardInLessonUseCase
