import { createSlice } from '@reduxjs/toolkit'
import { fetchSection } from '../../services/cloudServices'
import { baseAsyncThunk } from '../baseAsyncThunk'

const initialState = {
  loading: false,
  data: null,
  error: ''
}

export const fetchWriting = baseAsyncThunk('writing/fetchWriting', ({ unit }) => {
  return fetchSection({
    unit: unit,
    section: 'WRITING_EXERCISE_11'
  })
})

const writingSlice = createSlice({
  name: 'courseIntensity',
  initialState,
  reducers: {
    clearStates: state => {
      state.error = ''
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchWriting.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchWriting.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload.data
    })
    builder.addCase(fetchWriting.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const { clearStates } = writingSlice.actions

export default writingSlice.reducer
