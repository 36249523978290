import { useAppDispatch } from 'hooks/useAppDispatch'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { setPath } from 'redux/features/navigationSlice'

const PathListener = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(setPath(location.pathname))
  }, [location.pathname, dispatch])

  return null
}

export default PathListener
