import favoritesCategoryMapper from '../../services/mapper/favorite/favoritesCategoryMapper'
import { deleteFavoriteCategories } from '../../services/cloudServices'

const deleteFavoritesCategoryUseCase = async ({ type, id }) => {
  const result = await deleteFavoriteCategories({ id })

  return favoritesCategoryMapper(result, type)
}

export default deleteFavoritesCategoryUseCase
