import paymentResultMapper from '../../services/mapper/payment/paymentResultMapper'
import { fetchPaymentResult } from '../../services/cloudServices'

const getPaymentResultUseCase = async ({ paymentId }) => {
  const result = await fetchPaymentResult( { paymentId })

  return paymentResultMapper(result)
}

export default getPaymentResultUseCase
