import { orderBy } from 'lodash'
import { fetchChooseGateway } from 'services/cloudServices'

const getGatewayUseCase = async () => {
  const result = await fetchChooseGateway(null)

  return orderBy(result, ['availability.priority'], ['asc'])
}

export default getGatewayUseCase
