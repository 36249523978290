import flashCardsMapper from '../../services/mapper/memoryBox/flashCardsMapper'
import { ASC } from '../../enums/globalEnums/globalEnums'
import { fetchAllUnitMemoryBoxFlashCards } from '../../services/cloudServices'

const getAllUnitFlashCardUseCase = async ({ categoryId, searchTerm, sortBy, sortKey, unitId, isVocab }) => {
  const result = await fetchAllUnitMemoryBoxFlashCards(
    {
      categoryId: categoryId,
      frontText: searchTerm,
      sortAsc: sortBy === ASC,
      sortBy: sortKey,
      unitId: unitId,
      isVocab: isVocab
    }
  )

  return flashCardsMapper(result)
}

export default getAllUnitFlashCardUseCase
