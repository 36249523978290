export default [
  {
    translationKey: 'GATEWAY_CAPTCHA_VALIDATION_FAILED',
    translationValue: 'La validation du Captcha a échoué.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_CREATE_FAILED',
    translationValue: 'Échec de la création de l\'utilisateur.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_NOT_FOUND',
    translationValue: 'Le nom d\'utilisateur n\'existe pas.'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_INCORRECT',
    translationValue: 'Le mot de passe est incorrect!'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_EMPTY',
    translationValue: 'Le champ du mot de passe ne peut pas être vide.'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_LENGTH',
    translationValue: 'Le mot de passe doit contenir au moins trois caractères.'
  },
  {
    translationKey: 'USER_MANAGEMENT_REPASSWORD_EMPTY',
    translationValue: 'Le champ de confirmation du mot de passe est obligatoire.'
  },
  {
    translationKey: 'USER_MANAGEMENT_REPASSWORD_NOT_MATCH',
    translationValue: 'Le mot de passe et sa confirmation doivent être identiques.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USERNAME_EMPTY',
    translationValue: 'Les mots de passe doivent être identiques.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_INVALID',
    translationValue: 'L\'utilisateur n\'est pas valide.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_EXPIRED',
    translationValue: 'Le code de confirmation a expiré.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_INVALID',
    translationValue: 'Le code de confirmation n\'est pas valide.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_EMPTY',
    translationValue: 'Veuillez entrer le code de confirmation.'
  },
  {
    translationKey: 'USER_MANAGEMENT_OTP_RETRY',
    translationValue: 'Veuillez attendre deux minutes.'
  },
  {
    translationKey: 'PAYMENT_IP_INVALID',
    translationValue: 'Votre adresse IP n\'est pas valide.'
  },
  {
    translationKey: 'PAYMENT_REGION_INVALID',
    translationValue: 'Le code de réduction n\'est pas valide dans votre région.'
  },
  {
    translationKey: 'PAYMENT_CODE_EXPIRED',
    translationValue: 'Le code de réduction a expiré.'
  },
  {
    translationKey: 'PAYMENT_CODE_UNAVAILABLE',
    translationValue: 'Le code de réduction n\'est plus disponible.'
  },
  {
    translationKey: 'PAYMENT_CODE_USED',
    translationValue: 'Ce code de réduction a déjà été utilisé.'
  },
  {
    translationKey: 'CORE_AI_TOKEN_AMOUNT',
    translationValue: "Vous n'avez pas assez de jetons."
  },
  {
    translationKey: 'CORE_AI_ROLE_DESCRIPTION_INVALID',
    translationValue: 'Il n\'existe aucune description de rôle avec cet ID.'
  },
  {
    translationKey: 'CORE_AI_PROMPT_ID_INVALID',
    translationValue: 'Il n\'existe aucun prompt avec cet ID.'
  },
  {
    translationKey: 'CORE_AI_FEEDBACK_NOT_FOUND',
    translationValue: 'Commentaire introuvable.'
  },
  {
    translationKey: 'CORE_FILE_NOT_ENOUGH_SPACE',
    translationValue: 'Espace de stockage insuffisant.'
  },
  {
    translationKey: 'CORE_FAVORITE_CATEGORY_EXISTS',
    translationValue: 'Une catégorie avec ce nom existe déjà!'
  },
  {
    translationKey: 'CORE_FAVORITE_CATEGORY_NOT_FOUND',
    translationValue: 'Catégorie introuvable!'
  },
  {
    translationKey: 'CORE_FAVORITE_ITEM_EXISTS',
    translationValue: 'Cet élément existe déjà dans la catégorie sélectionnée.'
  },
  {
    translationKey: 'CORE_MEMORY_BOX_CARD_EXISTS',
    translationValue: 'Cette carte existe déjà.'
  },
  {
    translationKey: 'CORE_MEMORY_BOX_CATEGORY_EXISTS',
    translationValue: 'Une catégorie de flashcards avec ce nom existe déjà.'
  },
  {
    translationKey: 'CORE_MEMORY_NOTEPAD_TITLE_EXISTS',
    translationValue: 'Ce titre existe déjà ! Choisissez un autre titre.'
  },
  {
    translationKey: 'CORE_DICTIONARY_WORD_NOT_FOUND',
    translationValue: 'Le mot n\'a pas été trouvé dans notre bibliothèque.'
  },
  {
    translationKey: 'PAYMENT_CODE_INVALID',
    translationValue: 'Le code de réduction est incorrect. Veuillez vérifier et réessayer.'
  },
  {
    translationKey: 'OOPS_SOMETHING_WENT_WRONG',
    translationValue: 'Oups ! Quelque chose s\'est mal passé.'
  },
  {
    translationKey: 'CORE_TURN_OFF_LEVEL_INVALID',
    translationValue: 'Vous ne pouvez pas activer les niveaux précédents.'
  },
  {
    translationKey: 'CORE_TURN_OFF_UNIT_INVALID',
    translationValue: 'Vous ne pouvez pas désactiver les unités précédentes.'
  },
  {
    translationKey: 'CORE_TURN_ON_UNIT_INVALID',
    translationValue: 'Vous ne pouvez pas activer les unités précédentes.'
  }
]
