import levelsMapper from '../../services/mapper/levelAndUnit/levelsMapper'
import { fetchLevelsAndUnits } from '../../services/cloudServices'

const levelsAndUnitsUseCase = async () => {
  const response = await fetchLevelsAndUnits(null)

  return levelsMapper(response)
}

export default levelsAndUnitsUseCase
