import { updateFavoriteCategories } from '../../services/cloudServices'
import favoritesCategoryMapper from '../../services/mapper/favorite/favoritesCategoryMapper'

const updateFavoritesCategoryUseCase = async ({ type, id, name, color }) => {
  const result = await updateFavoriteCategories({ id, name, color })

  return favoritesCategoryMapper(result, type)
}

export default updateFavoritesCategoryUseCase
