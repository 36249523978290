import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import memoryTypeTestUseCase from '../../useCase/memoryTypeTestUseCase/memoryTypeTestUseCase'
import { languagesEnums } from '../../enums/languageEnums/languageEnums'
import { ENGLISH, NATIVE } from '../../enums/roboTeacher/dataStatusEnums'
import userAxiosInstance from '../../apis/userAxiosInstance'
import { updateSpacedRepetitionActionService } from '../../services/cloudServices'

const initialState = {
  loading: false,
  testNumber: null,
  questions: [],
  memoryTestResult: null,
  error: '',
  pairWords: [],
  currentStep: 2,
  currentRound: 0,
  showRoundStatus: false,
  pairWordsAnswers: [],
  shuffledPairWords: [],
  currentWords: {
    pairWordId: null,
    keyWord: null,
    valueWord: null
  },
  data: null,
  showingWords: false,
  showingWordsCounter: 0,
  pairWordsCurrentStep: 1
}

export const fetchWarmUpWords = createAsyncThunk('memoryTest/fetchWarmUpWords', (arg, { getState }) => {
  const { translation } = getState()
  return userAxiosInstance
    .get('/v1/evaluation-tests/memory-type-test', {
      params: {
        warmUp: true
      }
    })
    .then(response => {
      return {
        data: {
          english: response.data?.english,
          native: response.data?.nativeLanguage
        },
        selectedTranslationLanguage: translation.selectedTranslationLanguage,
        status: response.status
      }
    })
})

export const postMemoryTest = createAsyncThunk('memoryTest/postMemoryTest', ({ result }) => {
  return userAxiosInstance.post('/v1/evaluation-tests/memory-type-test', result).then(response => {
    return {
      data: response.data,
      status: response.status
    }
  })
})

export const fetchMemoryTypeTest = createAsyncThunk('memoryTest/fetchMemoryTypeTest', async (arg, { getState }) => {
  const { translation } = getState()
  const response = await memoryTypeTestUseCase()

  return {
    data: response,
    selectedTranslationLanguage: translation.selectedTranslationLanguage
  }
})

export const updateSpacedRepetitionAction = createAsyncThunk(
  'memoryTest/updateSpacedRepetitionAction',
  ({ spacedRepetition }) => {
    return updateSpacedRepetitionActionService({ spacedRepetition })
  }
)

const memoryTestSlice = createSlice({
  name: 'memoryTest',
  initialState,
  reducers: {
    setPairWordsAnswers: (state, action) => {
      state.pairWordsAnswers = [...state.pairWordsAnswers, action.payload]
    },
    setShowingWords: (state, action) => {
      state.showingWords = action.payload
    },
    setCurrentWords: (state, action) => {
      state.currentWords = action.payload
    },
    setShowingWordsCounter: (state, action) => {
      state.showingWordsCounter = action.payload
    },
    increaseShowingWordsCounter: state => {
      state.showingWordsCounter = state.showingWordsCounter + 1
    },
    setShuffledPairWords: (state, action) => {
      state.shuffledPairWords = action.payload
    },
    setPairWordsCurrentStep: (state, action) => {
      state.pairWordsCurrentStep = action.payload
    },
    setShowRoundStatus: (state, action) => {
      state.showRoundStatus = action.payload
    },
    setCurrentRound: (state, action) => {
      state.currentRound = action.payload
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload
    },
    setMemoryTypeTestLanguage: (state, action) => {
      if (action.payload === ENGLISH) {
        state.pairWords = state.data?.english?.pairWords ?? []
        state.questions = state.data?.english?.questions ?? []
      } else if (action.payload === NATIVE) {
        state.pairWords = state.data?.native?.pairWords ?? []
        state.questions = state.data?.native?.questions ?? []
      }
    },
    clearMemoryTestStates: state => {
      state.loading = false
      state.testNumber = null
      state.questions = []
      state.memoryTestResult = null
      state.error = ''
      state.pairWords = []
      state.currentStep = 2
      state.currentRound = 0
      state.showRoundStatus = false
      state.pairWordsAnswers = []
      state.shuffledPairWords = []
      state.currentWords = {
        pairWordId: null,
        keyWord: null,
        valueWord: null
      }
      state.data = null
      state.showingWords = false
      state.showingWordsCounter = 0
      state.pairWordsCurrentStep = 1
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchWarmUpWords.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchWarmUpWords.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload.data

      if (action.payload.selectedTranslationLanguage?.key === languagesEnums.ENGLISH_LANGUAGE) {
        state.pairWords = action.payload.data.english?.pairWords
      } else {
        state.pairWords = action.payload.data.native?.pairWords
      }
    })
    builder.addCase(fetchWarmUpWords.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(fetchMemoryTypeTest.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchMemoryTypeTest.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload.data
      state.currentStep = 3
      state.showRoundStatus = true

      if (action.payload.selectedTranslationLanguage?.key === languagesEnums.ENGLISH_LANGUAGE) {
        state.pairWords = action.payload.data.english?.pairWords
        state.questions = action.payload.data.english?.questions
      } else {
        state.pairWords = action.payload.data.native?.pairWords
        state.questions = action.payload.data.native?.questions
      }
    })
    builder.addCase(fetchMemoryTypeTest.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(postMemoryTest.pending, state => {
      state.loading = true
    })
    builder.addCase(postMemoryTest.fulfilled, (state, action) => {
      state.loading = false
      state.memoryTestResult = action.payload.data
    })
    builder.addCase(postMemoryTest.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(updateSpacedRepetitionAction.pending, state => {
      state.loading = true
    })
    builder.addCase(updateSpacedRepetitionAction.fulfilled, state => {
      state.loading = false
    })
    builder.addCase(updateSpacedRepetitionAction.rejected, state => {
      state.loading = false
    })
  }
})

export const {
  setPairWordsAnswers,
  setShowingWords,
  setCurrentWords,
  setShowingWordsCounter,
  setShuffledPairWords,
  setPairWordsCurrentStep,
  setShowRoundStatus,
  setCurrentRound,
  setCurrentStep,
  setMemoryTypeTestLanguage,
  clearMemoryTestStates
} = memoryTestSlice.actions

export default memoryTestSlice.reducer
