export default [
  {
    translationKey: 'GATEWAY_CAPTCHA_VALIDATION_FAILED',
    translationValue: 'Ошибка проверки Captcha.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_CREATE_FAILED',
    translationValue: 'Не удалось создать пользователя.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_NOT_FOUND',
    translationValue: 'Имя пользователя не существует.'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_INCORRECT',
    translationValue: 'Неверный пароль!'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_EMPTY',
    translationValue: 'Поле пароля не может быть пустым.'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_LENGTH',
    translationValue: 'Пароль должен содержать минимум три символа.'
  },
  {
    translationKey: 'USER_MANAGEMENT_REPASSWORD_EMPTY',
    translationValue: 'Поле повторного ввода пароля не может быть пустым.'
  },
  {
    translationKey: 'USER_MANAGEMENT_REPASSWORD_NOT_MATCH',
    translationValue: 'Пароль и повторный пароль должны совпадать.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USERNAME_EMPTY',
    translationValue: 'Поле имени пользователя не может быть пустым.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_INVALID',
    translationValue: 'Пользователь недействителен.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_EXPIRED',
    translationValue: 'Код подтверждения истёк.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_INVALID',
    translationValue: 'Код подтверждения недействителен.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_EMPTY',
    translationValue: 'Пожалуйста, введите код подтверждения.'
  },
  {
    translationKey: 'USER_MANAGEMENT_OTP_RETRY',
    translationValue: 'Пожалуйста, подождите две минуты.'
  },
  {
    translationKey: 'PAYMENT_IP_INVALID',
    translationValue: 'Ваш IP-адрес недействителен.'
  },
  {
    translationKey: 'PAYMENT_REGION_INVALID',
    translationValue: 'Этот код скидки недействителен в вашем регионе.'
  },
  {
    translationKey: 'PAYMENT_CODE_EXPIRED',
    translationValue: 'Срок действия кода скидки истёк.'
  },
  {
    translationKey: 'PAYMENT_CODE_UNAVAILABLE',
    translationValue: 'Этот код скидки больше недоступен.'
  },
  {
    translationKey: 'PAYMENT_CODE_USED',
    translationValue: 'Этот код скидки уже использован.'
  },
  {
    translationKey: 'CORE_AI_TOKEN_AMOUNT',
    translationValue: 'У вас недостаточно токенов.'
  },
  {
    translationKey: 'CORE_AI_ROLE_DESCRIPTION_INVALID',
    translationValue: 'Описание роли с этим ID не найдено.'
  },
  {
    translationKey: 'CORE_AI_PROMPT_ID_INVALID',
    translationValue: 'Подсказка с этим ID не найдена.'
  },
  {
    translationKey: 'CORE_AI_FEEDBACK_NOT_FOUND',
    translationValue: 'Отзыв не найден.'
  },
  {
    translationKey: 'CORE_FILE_NOT_ENOUGH_SPACE',
    translationValue: 'Недостаточно места в хранилище.'
  },
  {
    translationKey: 'CORE_FAVORITE_CATEGORY_EXISTS',
    translationValue: 'Категория с таким названием уже существует!'
  },
  {
    translationKey: 'CORE_FAVORITE_CATEGORY_NOT_FOUND',
    translationValue: 'Категория не найдена!'
  },
  {
    translationKey: 'CORE_FAVORITE_ITEM_EXISTS',
    translationValue: 'Этот элемент уже существует в выбранной категории.'
  },
  {
    translationKey: 'CORE_MEMORY_BOX_CARD_EXISTS',
    translationValue: 'Эта карточка уже существует.'
  },
  {
    translationKey: 'CORE_MEMORY_BOX_CATEGORY_EXISTS',
    translationValue: 'Категория карточек с таким названием уже существует.'
  },
  {
    translationKey: 'CORE_MEMORY_NOTEPAD_TITLE_EXISTS',
    translationValue: 'Такой заголовок уже существует! Выберите другой.'
  },
  {
    translationKey: 'CORE_DICTIONARY_WORD_NOT_FOUND',
    translationValue: 'Слово не найдено в нашей библиотеке.'
  },
  {
    translationKey: 'PAYMENT_CODE_INVALID',
    translationValue: 'Код скидки неверен. Проверьте его и попробуйте снова.'
  },
  {
    translationKey: 'OOPS_SOMETHING_WENT_WRONG',
    translationValue: 'Упс! Что-то пошло не так.'
  },
  {
    translationKey: 'CORE_TURN_OFF_LEVEL_INVALID',
    translationValue: 'Вы не можете включить предыдущие уровни.'
  },
  {
    translationKey: 'CORE_TURN_OFF_UNIT_INVALID',
    translationValue: 'Вы не можете отключить предыдущие модули.'
  },
  {
    translationKey: 'CORE_TURN_ON_UNIT_INVALID',
    translationValue: 'Вы не можете включить предыдущие модули.'
  }
]
