import { inquiryActionEnums } from 'enums/inquiryEnums/inquiryEnums'
import farsiToEnglishNum from 'util/farsiToEnglishNum/farsiToEnglishNum'
import { postInquiry } from 'services/cloudServices'
import { utmSourceToCampaignType } from 'util/utm'

const inquiryUseCase = async data => {
  const response = await postInquiry(
    {
      authData: {
        platform: data?.authData?.platform,
        deviceId: data?.authData?.deviceId,
        username: farsiToEnglishNum(data?.authData?.username?.trim()),
        password: data?.authData?.password,
        rePassword: data?.authData?.rePassword,
        code: data?.authData?.code,
        region: data?.authData?.region,
        state: data?.authData?.state,
        phoneNumberPrefix: data?.authData?.phoneNumberPrefix,
        authenticationType: data?.authData?.authenticationType,
        campaignType: utmSourceToCampaignType(data?.utmSource)
      },
      captchaToken: data?.captchaToken
    }
  )

  return { ...response, action: response.action ?? inquiryActionEnums.DONE }
}

export default inquiryUseCase
