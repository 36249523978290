import unitStatusMapper from '../../services/mapper/unitStatus/unitStatusMapper'
import { fetchUnitStatus } from '../../services/cloudServices'

const unitStatusUseCase = async ({ unitId, sectionNumber, studyType, sections }) => {
  const response = await fetchUnitStatus({ unitId, studyType })

  return unitStatusMapper(response, sections, sectionNumber)
}

export default unitStatusUseCase
