import { motion } from 'framer-motion'

const FadeMotion = ({ children, ...rest }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      {...rest}
    >
      {children}
    </motion.div>
  )
}

export default FadeMotion
