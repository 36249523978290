import userInfoMapper from '../../services/mapper/userInfo/userInfoMapper'
import userSettingMapper from '../../services/mapper/userSetting/userSettingMapper'
import { fetchAppUser } from '../../services/cloudServices'

const appUserUseCase = async () => {
  const result = await fetchAppUser( null)

  return {
    info: userInfoMapper(result?.info),
    setting: userSettingMapper(result?.setting),
    access: result?.access,
    action: result?.action,
    pwaVersion: result?.pwaVersion
  }
}

export default appUserUseCase
