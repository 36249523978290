import { removeFromBoxFlashCard } from '../../services/cloudServices'

const removeAllUnitFromBoxUseCase = async ({ unitIds }) => {
  const result = await removeFromBoxFlashCard(
    {
      unitIds: unitIds
    }
  )

  return result
}

export default removeAllUnitFromBoxUseCase
