import { GRAMMAR_WRAP } from '../../enums/sectionEnums/sectionEnums'
import roboTeacherMapper from './mapper/roboTeacherMapper'
import { fetchRoboTeacher } from '../../services/cloudServices'

export const getRoboTeacherUseCase = async ({ sectionType = GRAMMAR_WRAP.sectionType, unitId, platform = 'ALL' }) => {
  const result = await fetchRoboTeacher(
    {
      sectionType,
      unitId,
      platform
    }
  )
  return await roboTeacherMapper(result)
}
