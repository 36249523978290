export default [
  {
    translationKey: 'GATEWAY_CAPTCHA_VALIDATION_FAILED',
    translationValue: 'Captcha doğrulaması başarısız oldu.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_CREATE_FAILED',
    translationValue: 'Kullanıcı oluşturulamadı.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_NOT_FOUND',
    translationValue: 'Kullanıcı adı bulunamadı.'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_INCORRECT',
    translationValue: 'Şifre yanlış!'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_EMPTY',
    translationValue: 'Şifre alanı boş bırakılamaz.'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_LENGTH',
    translationValue: 'Şifre en az üç karakter içermelidir.'
  },
  {
    translationKey: 'USER_MANAGEMENT_REPASSWORD_EMPTY',
    translationValue: 'Şifre tekrar alanı boş bırakılamaz.'
  },
  {
    translationKey: 'USER_MANAGEMENT_REPASSWORD_NOT_MATCH',
    translationValue: 'Şifre ve tekrar şifresi aynı olmalıdır.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USERNAME_EMPTY',
    translationValue: 'Kullanıcı adı alanı boş bırakılamaz.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_INVALID',
    translationValue: 'Geçersiz kullanıcı.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_EXPIRED',
    translationValue: 'Doğrulama kodunun süresi doldu.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_INVALID',
    translationValue: 'Doğrulama kodu geçersiz.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_EMPTY',
    translationValue: 'Lütfen doğrulama kodunu girin.'
  },
  {
    translationKey: 'USER_MANAGEMENT_OTP_RETRY',
    translationValue: 'Lütfen iki dakika bekleyin.'
  },
  {
    translationKey: 'PAYMENT_IP_INVALID',
    translationValue: 'IP adresiniz geçersiz.'
  },
  {
    translationKey: 'PAYMENT_REGION_INVALID',
    translationValue: 'İndirim kodu bölgenizde geçerli değil.'
  },
  {
    translationKey: 'PAYMENT_CODE_EXPIRED',
    translationValue: 'İndirim kodunun süresi doldu.'
  },
  {
    translationKey: 'PAYMENT_CODE_UNAVAILABLE',
    translationValue: 'İndirim kodu artık mevcut değil.'
  },
  {
    translationKey: 'PAYMENT_CODE_USED',
    translationValue: 'Bu indirim kodu daha önce kullanılmış.'
  },
  {
    translationKey: 'CORE_AI_TOKEN_AMOUNT',
    translationValue: 'Yeterli tokeniniz yok.'
  },
  {
    translationKey: 'CORE_AI_ROLE_DESCRIPTION_INVALID',
    translationValue: 'Bu kimliğe sahip bir rol açıklaması bulunamadı.'
  },
  {
    translationKey: 'CORE_AI_PROMPT_ID_INVALID',
    translationValue: 'Bu kimliğe sahip bir prompt bulunamadı.'
  },
  {
    translationKey: 'CORE_AI_FEEDBACK_NOT_FOUND',
    translationValue: 'Geri bildirim bulunamadı.'
  },
  {
    translationKey: 'CORE_FILE_NOT_ENOUGH_SPACE',
    translationValue: 'Depolama alanı yetersiz.'
  },
  {
    translationKey: 'CORE_FAVORITE_CATEGORY_EXISTS',
    translationValue: 'Bu isimde bir kategori zaten mevcut!'
  },
  {
    translationKey: 'CORE_FAVORITE_CATEGORY_NOT_FOUND',
    translationValue: 'Kategori bulunamadı!'
  },
  {
    translationKey: 'CORE_FAVORITE_ITEM_EXISTS',
    translationValue: 'Bu öğe zaten seçili kategoride mevcut.'
  },
  {
    translationKey: 'CORE_MEMORY_BOX_CARD_EXISTS',
    translationValue: 'Bu kart zaten mevcut.'
  },
  {
    translationKey: 'CORE_MEMORY_BOX_CATEGORY_EXISTS',
    translationValue: 'Bu isimde bir flash kart kategorisi zaten mevcut.'
  },
  {
    translationKey: 'CORE_MEMORY_NOTEPAD_TITLE_EXISTS',
    translationValue: 'Bu başlık zaten var! Lütfen başka bir başlık seçin.'
  },
  {
    translationKey: 'CORE_DICTIONARY_WORD_NOT_FOUND',
    translationValue: 'Kelime kütüphanemizde bulunamadı.'
  },
  {
    translationKey: 'PAYMENT_CODE_INVALID',
    translationValue: 'İndirim kodu yanlış. Lütfen kontrol edip tekrar deneyin.'
  },
  {
    translationKey: 'OOPS_SOMETHING_WENT_WRONG',
    translationValue: 'Hata! Bir şeyler ters gitti.'
  },
  {
    translationKey: 'CORE_TURN_OFF_LEVEL_INVALID',
    translationValue: 'Önceki seviyeleri açamazsınız.'
  },
  {
    translationKey: 'CORE_TURN_OFF_UNIT_INVALID',
    translationValue: 'Önceki üniteleri devre dışı bırakamazsınız.'
  },
  {
    translationKey: 'CORE_TURN_ON_UNIT_INVALID',
    translationValue: 'Önceki üniteleri etkinleştiremezsiniz.'
  }
]
