import { marked } from 'marked'

import DOMPurify from 'dompurify'

export function shuffle(array) {
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}

export function emptyCache() {
  if ('caches' in window) {
    caches.keys().then(names => {
      // Delete all the cache files
      names.forEach(name => {
        caches.delete(name)
      })
    })

    // Makes sure the page reloads. Changes are only visible after you refresh.
    window.location.reload(true)
  }
}

export function isObject(obj) {
  return obj && typeof obj === 'object'
}

export function isArray(obj) {
  return isObject(obj) && obj instanceof Array
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

// Page Loader
const loader = document.querySelector('.loader')

// if you want to show the loader when React loads data again
export const showLoader = text => {
  if (text && typeof text === 'string') {
    document.getElementById('loading-title').innerHTML = `${text}`
  }
  loader.classList.remove('loader--hide')
}

export const hideLoader = () => {
  const el = document.getElementById('loading-title')
  el.innerHTML = ''
  loader.classList.add('loader--hide')
}

// Get filename extension
export const getFileExtension = filename => {
  return filename.split('.').pop()
}

// Get random number with min and max config
export const getRndInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

//To clear all data from localStorage and redirect the user to the login page
export const afterLogoutUser = () => {
  localStorage.clear()
  window.location.href = '/auth/login'
}

export const checkRTL = text => {
  const rtlChars = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]/

  return rtlChars.test(text)
}

// export const splitTextForAiChat = (text, isMarkdown = false) => {
//   if (isMarkdown) {
//     // Return plain text with bidirectional markers for Markdown
//     return text
//       .split(/(\s+)/) // Keep spaces
//       .map(word => (checkRTL(word) ? '\u200F' + word : '\u200E' + word))
//       .join('')
//   }

//   // Return JSX for normal div rendering
//   return text.split(/(\s+)/).map((word, index) => {
//     if (word.trim() === '') {
//       return <span key={index}>{word}</span>
//     }

//     return (
//       <span
//         key={index}
//         dir={checkRTL(word) ? 'rtl' : 'ltr'}
//         style={{
//           display: 'inline-block',
//           textAlign: checkRTL(word) ? 'right' : 'left',
//           unicodeBidi: 'isolate' // Ensures correct alignment
//         }}
//       >
//         {word}
//       </span>
//     )
//   })
// }

// const applyListStyles = html => {
//   const parser = new DOMParser()
//   const doc = parser.parseFromString(html, 'text/html')

//   // Apply styles to all <ul> elements
//   doc.querySelectorAll('ul').forEach(ul => {
//     ul.style.display = 'block'
//   })

//   // Apply styles to all <li> elements
//   doc.querySelectorAll('li').forEach(li => {
//     li.style.display = 'block'
//   })

//   return doc.body.innerHTML // Return modified HTML as string
// }

// const applyPTagStyle = html => {
//   return html.replace(/<p>/g, <p style='display: inline;' />)
// }

// const removeTags = html => {
//   return html.replace(/<\/?(ul|li)>/g, '') // Removes <ul>, and <li> but keeps content
// }

// export const renderMarkdownWithDirection = markdownText => {
//   let rawHtml = marked(markdownText)
//   rawHtml = removeTags(rawHtml)

//   const sanitizedHtml = DOMPurify.sanitize(rawHtml)
//   const words = sanitizedHtml.split(/(\s+)/)

//   let groupedText = []
//   let buffer = []
//   let currentDirection = checkRTL(words.find(w => w.trim() !== '')) ? 'rtl' : 'ltr'

//   words.forEach((word, index) => {
//     const trimmedWord = word.trim()

//     if (trimmedWord === '') {
//       buffer.push(word)

//       return
//     }

//     const isStandaloneHTMLTag = /^<(hr|br)([^>]*)?>$/i.test(trimmedWord)

//     if (isStandaloneHTMLTag) {
//       if (buffer.length > 0 && buffer.join('').trim() !== '') {
//         const content = buffer.join('').trim()
//         if (content) {
//           groupedText.push(
//             <span
//               key={groupedText.length}
//               dir={currentDirection}
//               style={{
//                 display: 'inline',
//                 unicodeBidi: 'isolate',
//                 textAlign: currentDirection === 'rtl' ? 'right' : 'left'
//               }}
//               dangerouslySetInnerHTML={{
//                 __html: `${DOMPurify.sanitize(content)}`
//               }}
//             />
//           )
//         }
//       }

//       buffer = []

//       groupedText.push(<div key={groupedText.length} dangerouslySetInnerHTML={{ __html: word }} />)

//       return
//     }

//     const isRTL = checkRTL(trimmedWord)
//     const wordDirection = isRTL ? 'rtl' : 'ltr'

//     // Group consecutive LTR words inside RTL text
//     if (currentDirection === 'rtl' && !isRTL) {
//       if (!buffer.ltrBuffer) {
//         buffer.ltrBuffer = [] // Initialize buffer for LTR words
//       }

//       buffer.ltrBuffer.push(word)

//       return
//     }

//     // If switching back to RTL, insert grouped LTR words first
//     if (buffer.ltrBuffer && buffer.ltrBuffer.length > 0) {
//       buffer.push(
//         `<span style="display: inline; direction: ltr; unicode-bidi: bidi-override; text-align: left;">${buffer.ltrBuffer.join(
//           ' '
//         )}</span>`
//       )

//       delete buffer.ltrBuffer // Reset LTR buffer after inserting
//     }

//     if (wordDirection !== currentDirection) {
//       if (buffer.length > 0 && buffer.join('').trim() !== '') {
//         const content = buffer.join('').trim()
//         if (content) {
//           groupedText.push(
//             <span
//               key={groupedText.length}
//               dir={currentDirection}
//               style={{
//                 display: 'inline',
//                 unicodeBidi: 'isolate',
//                 textAlign: currentDirection === 'rtl' ? 'right' : 'left'
//               }}
//               dangerouslySetInnerHTML={{
//                 __html: `<p>${DOMPurify.sanitize(content)}</p>`
//               }}
//             />
//           )
//         }
//       }

//       buffer = []

//       currentDirection = wordDirection
//     }

//     buffer.push(word)
//   })

//   // Push the last accumulated words in buffer (if not empty)
//   if (buffer.length > 0 && buffer.join('').trim() !== '') {
//     const content = buffer.join('').trim()
//     if (content) {
//       groupedText.push(
//         <span
//           key={groupedText.length}
//           dir={currentDirection}
//           style={{
//             display: 'inline',
//             unicodeBidi: 'isolate',
//             textAlign: currentDirection === 'rtl' ? 'right' : 'left'
//           }}
//           dangerouslySetInnerHTML={{
//             __html: `<p>${DOMPurify.sanitize(content)}</p>`
//           }}
//         />
//       )
//     }
//   }

//   return groupedText
// }

// Function to detect the language of a given text
function detectLanguage(text) {
  // Unicode ranges for common RTL languages (Arabic, Persian, Hebrew, Urdu, etc.)
  const rtlChars = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/
  const ltrChars = /[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/

  let rtlCount = 0
  let ltrCount = 0

  for (let i = 0; i < text.length; i++) {
    const char = text.charAt(i)

    if (rtlChars.test(char)) {
      rtlCount++
    } else if (ltrChars.test(char)) {
      ltrCount++
    }
  }

  return rtlCount > ltrCount ? 'rtl' : 'ltr'
}

// Function to set the direction of paragraphs
export function setParagraphDirections(html) {
  // Convert Markdown to HTML first
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')

  const paragraphs = doc.getElementsByTagName('p')

  for (let i = 0; i < paragraphs.length; i++) {
    const paragraph = paragraphs[i]
    const text = paragraph.textContent.trim()

    if (text) {
      paragraph.dir = detectLanguage(text)
    }
  }

  return DOMPurify.sanitize(doc.body.innerHTML)
}
