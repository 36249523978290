import { useEffect } from 'react'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { seenNotificationById } from 'redux/features/messages/messagesCategorySlice'
import { removeQueryParamsAndNavigate } from 'util/utility'
import { useLocation } from 'react-router'
import useQueryParams from 'hooks/useQueryParams'
import useLocalStorage from 'hooks/useLocalStorage'
import { LocalStorageKeys } from 'types/global'

/**
 * A functional component which initiates just once in App
 * This component is right under the BrowserRouter component from `react-router-dom`
 *
 * @return {JSX.Element} An empty JSX element
 */
const AppInRouter = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { getCurrentQueryParamsObject } = useQueryParams()
  const [utmSource, setUtmSource] = useLocalStorage(LocalStorageKeys.UTM_SOURCE, null)

  const checkNotificationId = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const notificationId = urlParams.get('seenNotificationId')
    if (notificationId) {
      dispatch(seenNotificationById({ id: notificationId }))
      removeQueryParamsAndNavigate(['seenNotificationId'])
    }
  }

  useEffect(() => {
    checkNotificationId()
  }, [location])

  // Save UTM parameters in local storage to send it to backend through inquiry service
  useEffect(() => {
    const currentQueryParams: any = getCurrentQueryParamsObject()
    if (currentQueryParams?.data_utm_source) {
      setUtmSource(currentQueryParams?.data_utm_source)
    }
  }, [])

  return <></>
}

export default AppInRouter
