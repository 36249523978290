import filesAndFoldersMapper from '../../services/mapper/notepad/filesAndFoldersMapper'
import { notepadEditFolder } from '../../services/cloudServices'

const editFolderUseCase = async ({ parentFolderId, folderId, title, color }) => {
  const result = await notepadEditFolder({ parentFolderId, folderId, title, color })

  return filesAndFoldersMapper(result)
}

export default editFolderUseCase
