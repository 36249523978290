import sentenceDataMapper from '../../services/mapper/sentenceData/sentenceDataMapper'
import { fetchSentenceData } from '../../services/cloudServices'

const sentenceDataUseCase = async ({ trackerNumber }) => {
  const data = await fetchSentenceData({ trackerNumber })

  return sentenceDataMapper(data)
}

export default sentenceDataUseCase
