import favoritesCategoryContentMapper from '../../services/mapper/favorite/favoritesCategoryContentMapper'
import { fetchFavoriteCategoryContent } from '../../services/cloudServices'

const getFavoritesCategoryContentUseCase = async ({
  type,
  categoryId,
  currentPage,
  postsPerPage,
  searchTerm,
  sortKey,
  sortBy
}) => {
  const result = await fetchFavoriteCategoryContent(
    { type, categoryId, currentPage, postsPerPage, searchTerm, sortKey, sortBy }
  )

  return favoritesCategoryContentMapper(result)
}

export default getFavoritesCategoryContentUseCase
