import {
  FINISH_EXERCISE,
  FINISH_SECTION,
  RESET_EXERCISE,
  START_EXERCISE,
  START_SECTION
} from '../enums/studyLogEnums/studyLogEnums'
import UnitDataStorage from '../classes/UnitDataStorage'
import { sectionActionType } from '../enums/sectionActionTypeEnums/sectionActionTypeEnums'
import { studyTypeEnum } from '../enums/userStudyEnums/userStudyEnums'
import studyTypeIsRepeat from '../operations/studyTypeIsRepeat'
import { postStudyLog } from '../services/cloudServices'

export default class SystemLog {
  constructor() {}

  info(...message) {
    if (process.env.NODE_ENV === 'development') {
      console.info('systemInfo:', ...message)
    }
  }

  error(...message) {
    if (process.env.NODE_ENV === 'development') {
      console.error('systemError:', ...message)
    }
  }

  warning(...message) {
    if (process.env.NODE_ENV === 'development') {
      console.warn('systemWarning:', ...message)
    }
  }

  async server(type) {
    const unitDataStorage = new UnitDataStorage()
    const unitData = unitDataStorage.getUnitData()

    if (unitData && (unitData.studyStatus === studyTypeEnum.NEW || studyTypeIsRepeat(unitData.studyStatus))) {
      switch (type) {
        case START_SECTION:
          this.info(START_SECTION, unitData)
          postStudyLog(
            {
              unitId: unitData?.unitNumber,
              sectionType: unitData?.sectionKey,
              type: unitData.studyStatus,
              action: sectionActionType.start
            }
          )
          break
        case FINISH_SECTION:
          this.info(FINISH_SECTION, unitData)
          postStudyLog(
            {
              unitId: unitData?.unitNumber,
              sectionType: unitData?.sectionKey,
              type: unitData.studyStatus,
              action: sectionActionType.finish
            }
          )
          break
        case START_EXERCISE:
          this.info(START_EXERCISE, unitData)
          postStudyLog(
            {
              unitId: unitData?.unitNumber,
              sectionType: unitData?.sectionKey,
              type: unitData.studyStatus,
              action: sectionActionType.start
            }
          )
          break
        // case RESET_EXERCISE:
        // this.info(RESET_EXERCISE, unitData)
        // postStudyLog(
        //   {
        //     unitId: unitData?.unitNumber,
        //     sectionType: unitData?.sectionKey,
        //     type: unitData.studyStatus,
        //     action: sectionActionType.reset
        //   }
        // )
        // break
        // case FINISH_EXERCISE:
        // postStudyLog(
        //   {
        //     unitId: unitData?.unitNumber,
        //     sectionType: unitData?.sectionKey,
        //     type: unitData.studyStatus,
        //     action: sectionActionType.finish
        //   }
        // )
        // break
      }
    }
  }
}
