import aiPromptMapper from '../../services/mapper/aiPrompt/aiPromptMapper'
import { postAiChatPrompt } from '../../services/cloudServices'

const aiPromptUseCase = async data => {
  const result = await postAiChatPrompt(data)

  return aiPromptMapper(result)
}

export default aiPromptUseCase
